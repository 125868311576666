import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AbstractService } from '@core/api';
import { CredentialService } from '@core/credential.service';
import { ScolaritePayment } from '@interface/scolarite-payment';
import { Response } from '@model/response';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class ScolaritePaiementsService extends AbstractService {

  constructor(
    private credentialService: CredentialService,
    private http: HttpClient
  ) {
    super();
  }

  getList(params?: HttpParams): Observable<ScolaritePayment[]> {
    this.endpoint = `/scolarite_payments.json`;

    if (this.credentialService.isAgence()) {
      this.endpoint = `/agences/${this.credentialService.credentials.user.agence_user.agence.id}${this.endpoint}`;
    }

    return this.http
      .get<Response>(this.endpoint, { params })
      .pipe(
        map((response) => {
          if (response.code === 0) {
            return response.scolarite_payments;
          }
          throw new Error(response.errorMessage);
        })
      );
  }

  getAll(data: any): Observable<any> {
    this.endpoint = `/scolarite_payments/all.json`;

    if (this.credentialService.isAgence()) {
      this.endpoint = `/agences/${this.credentialService.credentials.user.agence_user.agence.id}${this.endpoint}`;
    }

    return this.http
      .post<any>(this.endpoint, data);
  }

  create(data: any): Observable<boolean> {
    this.endpoint = `/scolarite_payments.json`;

    if (this.credentialService.isAgence()) {
      this.endpoint = `/agences/${this.credentialService.credentials.user.agence_user.agence.id}${this.endpoint}`;
    }

    return this.http
      .post<Response>(this.endpoint, data)
      .pipe(
        map((response) => {
          if (response.code === 0) {
            return true;
          }
          throw new Error(response.errorMessage);
        })
      );
  }

  read(id: number): Observable<ScolaritePayment> {
    this.endpoint = `/scolarite_payments/${id}.json`;

    if (this.credentialService.isAgence()) {
      this.endpoint = `/agences/${this.credentialService.credentials.user.agence_user.agence.id}${this.endpoint}`;
    }

    return this.http
      .get<Response>(this.endpoint)
      .pipe(
        map((response) => {
          if (response.code === 0) {
            return response.scolarite_payment;
          }
          throw new Error(response.errorMessage);
        })
      );
  }

}
