import { Registration } from '@interface/registration';
import { HttpParams } from '@angular/common/http';
import { AfterViewInit, Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { CredentialService } from '@core/credential.service';
import { untilDestroyed } from '@core/until-destroyed';
import { AgenceAcademicYear } from '@interface/agence-academic-year';
import { Classroom } from '@interface/classroom';
import { Filiere } from '@interface/filiere';
import { NiveauEtude } from '@interface/niveau-etude';
import { Secteur } from '@interface/secteur';
import { Student } from '@interface/student';
import { NavItem } from '@lidar/interfaces/nav-item';
import { AgenceAcademicYearStore } from '@lidar/stores/agence-academic-year.store';
import { ElementHeaderActions } from '@lidar/templates/content-template/content-template.component';
import { MessageService } from 'primeng';
import { Observable, of } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';
import { AcademicYearsService } from '../../app/admin/souclou-digital/academic-years/academic-years.service';
import { SouclouDigitalService } from '../../app/admin/souclou-digital/souclou-digital.service';
import { RegistrationsService } from '../../app/admin/souclou-digital/registrations/registrations.service';

@Component({
  selector: 'app-pre-inscrption',
  templateUrl: './pre-inscrption.component.html',
  styleUrls: ['./pre-inscrption.component.css']
})
export class PreInscrptionComponent implements OnInit, OnDestroy, AfterViewInit {
  navItems: NavItem[] = [
    { routerLink: ['/admin/inscriptions'], label: 'Inscriptions', routerLinkActive: 'active' },
  ];

  newRegistration = true;

  elementHeaderAction = ElementHeaderActions;
  errorData: string;
  studentForm: FormGroup;
  form: FormGroup;
  formFilter: FormGroup;
  fgroup: FormGroup;
  isLoading: boolean;
  isFilterLoading: boolean;
  classrooms: Classroom[];
  agenceAcademicYears$: Observable<AgenceAcademicYear[]>;
  registrations$: Observable<Registration[]>;
  secteurs: Secteur[];
  filieres: Filiere[];
  secteurs$: Observable<Secteur[]>;
  filieres$: Observable<Filiere[]>;
  classrooms$: Observable<Classroom[]>;
  niveauEtudes$: Observable<NiveauEtude[]>;
  phoneNumber: string;
  agenceAcademicYyearsId: any;
  agenceSelected:any
  @ViewChild('fatherRef', { static: false }) fatherRef: ElementRef;
  @ViewChild('motherRef', { static: false }) motherRef: ElementRef;

  constructor(
    private agenceAcademicYearStore: AgenceAcademicYearStore,
    private souclouDigitalService: SouclouDigitalService,
    private registrationsService: RegistrationsService,
    private academicYearsService: AcademicYearsService,
    private credentialService: CredentialService,
    private messageService: MessageService,
    private fb: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
  ) {
  }

  get f() {
    return this.form;
  }

  get student() {
    return this.f.get('student');
  }

  get sexe() {
    return this.student.get('sexe');
  }

  get gsanguin() {
    return this.student.get('groupe_sanguin');
  }

  get affecte() {
    return this.student.get('affecte');
  }

  get doublant() {
    return this.f.get('doublant');
  }

  get regime() {
    return this.student.get('regime');
  }

  get nom() {
    return this.student.get('nom');
  }

  get prenoms() {
    return this.student.get('prenoms');
  }

  get birthdate() {
    return this.student.get('birthdate');
  }

  get birthplace() {
    return this.student.get('birthplace');
  }

  get adresse() {
    return this.student.get('adresse');
  }

  get matricule() {
    return this.student.get('matricule');
  }

  get telephone() {
    return this.student.get('telephone');
  }

  get nationalite() {
    return this.student.get('nationalite');
  }

  get adresseEmail() {
    return this.student.get('adresse_email');
  }

  get handicap() {
    return this.student.get('handicap');
  }

  get niveauEtudeId() {
    return this.f.get('niveau_etude_id');
  }

  get filiereId() {
    return this.f.get('filiere_id');
  }

  get classroomId() {
    return this.f.get('classroom_id');
  }

  ngAfterViewInit() {
  }

  ngOnInit() {
    this.formFilter = this.fb.group({
      agence_academic_year_id: ['', Validators.required],
      classe: ['', Validators.required],
      nom: ['', Validators.required],
    });

    this.form = this.fb.group({
      statut: [true],
      inscr_type: ['pre-inscription', Validators.required],
      matricule_orientation: [''],
      matricule_menet: [''],
      scolarite_payeur: [''],
      matricule: [''],
      serie_bac: [''],
      numero_table: [''],
      etablissement_origine: [''],
      classe_frequente: [''],
      diplome_obtenu: [''],
      specialite: [''],
      agence_id: ['', Validators.required],
      // filiere_id: ['', Validators.required],
      // niveau_etude_id: ['', Validators.required],
      agence_academic_year_id: ['', Validators.required],
      // classroom_id: ['', Validators.required],
      regime: [],
      doublant: [],
      prise_charge: [false],
      prise_charge_origine: [''],
      prise_charge_montant: [''],
      student: this.fb.group({
        scolarite_payeur: ['tuteur'],
        sexe: ['m'],
        matricule: [''],
        adresse: [''],
        telephone: [''],
        adresse_email: ['', [Validators.email]],
        affecte: ['0'],
        regime: [''],
        nom: ['', Validators.required],
        prenoms: ['', Validators.required],
        birthdate: ['', [Validators.required]],
        birthplace: ['', Validators.required],
        nationalite: [''],
        groupe_sanguin: [''],
        handicap: [''],
        statut: [true],
        family: this.fb.group({
          father: this.fb.group({
            inconnu: [],
            type: ['pere'],
            nom: ['', [Validators.required]],
            prenoms: ['', [Validators.required]],
            fonction: ['', [Validators.required]],
            adresse: ['', [Validators.required]],
            phone: ['', [Validators.required]],
            email: ['', [Validators.email]],
          }),
          mother: this.fb.group({
            inconnu: [],
            type: ['mere'],
            nom: ['', [Validators.required]],
            prenoms: ['', [Validators.required]],
            fonction: ['', [Validators.required]],
            adresse: ['', [Validators.required]],
            phone: ['', [Validators.required]],
            email: ['', [Validators.email]],
          }),
        }),
        tutor: this.fb.group({
          who: ['tuteur'],
          type: ['tuteur'],
          nom: ['', [Validators.required]],
          prenoms: ['', [Validators.required]],
          fonction: ['', [Validators.required]],
          adresse: ['', [Validators.required]],
          phone: ['', [Validators.required]],
          email: ['', [Validators.email]],
        }),
      }),
    });

    this.route.paramMap.subscribe(params => {
      this.agenceAcademicYyearsId = params.get('id');
      console.log('agenceAcademicYyears ID:', this.agenceAcademicYyearsId); 
    });

    // Initialize form

    this.student.get('family').get('father').get('inconnu').setValue(true);
    this.student.get('family').get('mother').get('inconnu').setValue(true);

    this.toggleUse('pere', { checked: this.student.get('family').get('father').get('inconnu').value });
    this.toggleUse('mere', { checked: this.student.get('family').get('mother').get('inconnu').value });

    this.checkAff(false);
    this.checkPriseCharge(false);
    this.onTuteurCheck(null);

   // if (this.credentialService.isAgence()) {
      
    //}

    // Subscribe to store
    // this.agenceAcademicYearStore
    //   .state$
    //   .pipe(
    //     tap(next => {
    //       if (next.active) {
    //         console.log('next-active',next.active.id)
    //         this.form.get('agence_academic_year_id').setValue(next.active.id);
    //         this.secteurs$ = this.retrieveSecteurs();
    //       }
    //     })
    //   )
    //   .subscribe();

    this.getAgenceAcademieYearDetail();
    this.isFilterLoading = false;
    this.isLoading = false;
  }

  ngOnDestroy(): void {
  }



  retrieveAgenceAcademicYears(): Observable<AgenceAcademicYear[]> {
    const httpParams = new HttpParams();
    var endpoint_out =  `/agences/${this.agenceSelected.id}/agence_academic_years/list.json`;
    return this.souclouDigitalService.getAgenceAcademicYears(httpParams,true,endpoint_out);
  }

  getAgenceAcademieYearDetail() {
    const httpParams = new HttpParams();
    this.souclouDigitalService.getAgenceAcademicYearsDetail(httpParams,this.agenceAcademicYyearsId).subscribe((reponse:any)=>{
      this.agenceSelected = reponse.agence_academic_year.agence;
      this.form.get('agence_academic_year_id').setValue(this.agenceAcademicYyearsId);
      this.form.get('agence_id').setValue(this.agenceSelected.id);
      this.agenceAcademicYears$ = this.retrieveAgenceAcademicYears();
      this.secteurs$ = this.retrieveSecteurs();
      console.log('reponse',this.agenceSelected)

    })
  }

  submitFilter() {
    if (this.formFilter.valid) {
      this.isFilterLoading = true;
      this.errorData = null;

      this.registrations$ = this.registrationsService
        .search_in_out(this.formFilter.value, Number(this.formFilter.get('agence_academic_year_id').value),this.agenceSelected.id)
        .pipe(
          tap(data => {
            this.isFilterLoading = false;
          }),
          catchError(err => {
            this.errorData = err;
            this.messageService.add({ severity: 'error', summary: 'Erreur', detail: err });
            this.isFilterLoading = false;

            return of(err);
          })
        );
    }
  }

  searchClasse(event: any) {
    // this.agenceAcademicYearStore.state$
    //   .pipe(
    //     tap(next => {
          const params: HttpParams = new HttpParams()
            .set('query', event.query)
            .set('agence_academic_year_id', this.formFilter.get('agence_academic_year_id').value)
            .set('agence_id', String(this.agenceSelected.id));
            console.log('HttpParams',HttpParams);
          this.souclouDigitalService
            .getClassroomsOut(params,String(this.agenceSelected.id))
            .pipe(
              tap(res => {
                this.classrooms = res
              })
            ).subscribe();
      //   })
      // )
      // .subscribe();
  }

  checkAff(on: boolean) {
    this.matricule.clearValidators();

    if (on) {
      this.matricule.enable();
      this.matricule.setValidators(Validators.required);
    } else {
      this.matricule.disable();
    }
  }

  checkPriseCharge(on: boolean) {
    this.form.get('prise_charge_origine').clearValidators();
    this.form.get('prise_charge_montant').clearValidators();

    if (on) {
      this.form.get('prise_charge_origine').enable();
      this.form.get('prise_charge_montant').enable();

      this.form.get('prise_charge_origine').setValidators(Validators.required);
      this.form.get('prise_charge_montant').setValidators(Validators.required);
    } else {
      this.form.get('prise_charge_origine').disable();
      this.form.get('prise_charge_montant').disable();
    }
  }

  onNiveauChange() {
    this.filiereId.setValue('');
    this.classroomId.setValue('');
    this.filieres = [];
    this.classrooms = [];
    if (this.niveauEtudeId.value) {
      this.secteurs.forEach(secteur => {
        secteur.niveau_etudes.forEach(niveauEtude => {
          if (niveauEtude.id === Number(this.niveauEtudeId.value)) {
            this.filieres = niveauEtude.filieres;
          }
        });
      });
    }
  }

  onFiliereChange() {
    this.classroomId.setValue('');
    this.classrooms = [];
    if (this.filiereId.value) {
      this.filieres.forEach(filiere => {
        if (filiere.id === Number(this.filiereId.value)) {
          filiere.classrooms.forEach(classroom => {
            // if (classroom.registrations.length < classroom.student_max) {
            this.classrooms.push(classroom);
            // }
          });
        }
      });
    }
  }

  retrieveSecteurs(): Observable<Secteur[]> {
    const httpParams = new HttpParams()
      .set('agence_id', String(this.agenceSelected.id))
      .set('agence_academic_year_id', this.agenceAcademicYyearsId)
      .set('distinct', 'Secteurs.name')
      .set('strict', 'NiveauEtudes');
    console.log('httpParams',httpParams)
    return this.souclouDigitalService
      .getSecteurs(httpParams)
      .pipe(
        tap((next: Secteur[]) => {
          this.secteurs = next;
          this.niveauEtudeId.setValue('');
          this.filiereId.setValue('');
          this.classroomId.setValue('');
        })
      );
  }

  toggleControlState(control: AbstractControl, state: boolean) {
    if (!state) {
      control.get('nom').disable();
      control.get('prenoms').disable();
      control.get('adresse').disable();
      control.get('fonction').disable();
      control.get('phone').disable();
    } else {
      control.get('nom').enable();
      control.get('prenoms').enable();
      control.get('adresse').enable();
      control.get('fonction').enable();
      control.get('phone').enable();
    }
  }

  updateValidators(control: AbstractControl, clear = false): void {
    if (clear) {
      control.clearValidators();
    }
    control.updateValueAndValidity();
  }

  toggleControlValidity(control: AbstractControl, state: boolean) {
    this.updateValidators(control, true);

    control.get('nom').setValidators(state ? [Validators.required] : []);
    control.get('prenoms').setValidators(state ? [Validators.required] : []);
    control.get('adresse').setValidators(state ? [Validators.required] : []);
    control.get('fonction').setValidators(state ? [Validators.required] : []);
    control.get('phone').setValidators(state ? [Validators.required] : []);

    this.updateValidators(control);
  }

  onTuteurCheck($event: any) {
    switch (this.student.get('tutor').get('who').value) {
      case 'pere':
        // this.toggleControlValidity(this.student.get('family').get('father'), true);
        this.toggleControlState(this.student.get('tutor'), false);
        break;
      case 'mere':
        //  this.toggleControlValidity(this.student.get('family').get('mother'), true);
        this.toggleControlState(this.student.get('tutor'), false);
        break;
      case 'tuteur':
        // this.toggleControlValidity(this.student.get('family').get('father'), false);
        // this.toggleControlValidity(this.student.get('family').get('mother'), false);
        // this.toggleControlValidity(this.student.get('tutor'), true);
        this.toggleControlState(this.student.get('tutor'), true);
        break;
    }
  }

  onSPCheck($event: any) {
  }

  onAgenceAcademicYearChange() {
    this.form.get('classroom_id').setValue('');

    /*
    const params = new HttpParams()
      .set(
        'agence_academic_year_id',
        this.form.get('agence_academic_year_id').value
      );

    this.niveauEtudes$ = this.registrationsService.getNiveauEtudes(params);
     */

    this.secteurs$ = this.retrieveSecteurs();
  }

  isInvalid(control: AbstractControl) {
    return control.invalid && (control.dirty || control.touched);
  }

  goBack() {
    this.router.navigate(['/admin/inscriptions']).then();
  }

  submit() {
    this.form.get('scolarite_payeur').setValue(this.student.get('scolarite_payeur').value);

    if (this.f.valid) {
      if (this.form.get('scolarite_payeur').value === 'pere') {
        if (this.student.get('family').get('father').get('inconnu').value === true) {
          this.messageService
            .add({
              closable: true,
              severity: 'error',
              summary: 'Personne en charge de la scolarité',
              detail: `Vous devez renseigner les informations du père`,
            });
          return;
        }
      }

      if (this.form.get('scolarite_payeur').value === 'mere') {
        if (this.student.get('family').get('mother').get('inconnu').value === true) {
          this.messageService
            .add({
              closable: true,
              severity: 'error',
              summary: 'Personne en charge de la scolarité',
              detail: `Vous devez renseigner les informations de la mère`,
            });
          return;
        }
      }

      if (this.form.get('scolarite_payeur').value === 'tuteur') {
        if (this.student.get('tutor').get('who').value !== 'tuteur') {
          this.messageService
            .add({
              closable: true,
              severity: 'error',
              summary: 'Personne en charge de la scolarité',
              detail: `Vous devez renseigner les informations du tuteur légal`,
            });
          return;
        }
      }

      this.f.disable();

      this.isLoading = true;

      this.registrationsService
        .createOnOut(this.f.value,this.agenceSelected.id)
        .pipe(
          untilDestroyed(this),
          map((response: Student) => {
            this.messageService
            .add({
              severity: 'success',
              summary: 'Nouvelle inscription',
              detail: `< ${this.prenoms.value} ${this.nom.value} > a été ajouté avec succès.`
            });
            window.location.reload();
            // this.router.navigate(['/admin/inscriptions']).then(value => {
            //   if (value) {
            //     this.messageService
            //       .add({
            //         severity: 'success',
            //         summary: 'Nouvelle inscription',
            //         detail: `< ${this.prenoms.value} ${this.nom.value} > a été ajouté avec succès.`
            //       });
            //   }
            // });
          }),
          catchError(err => {
            this.isLoading = false;
            this.messageService.add({ severity: 'error', summary: 'Erreur', detail: err });
            this.f.enable();

            return of(err);
          })
        )
        .subscribe();
    }
  }

  hasError(control: AbstractControl, $event: any) {
    if (!$event) {
      control.setValue('');
      /*
      control.setErrors({
        required: true
      });*/
    }
  }

  getNumber(control: AbstractControl, $event: any) {
    control.setValue($event);
  }

  telInputObject($event: any) {
    $event.setCountry('ci');
  }

  onCountryChange($event: any) {
  }

  toggleUse(tag: string, event: any) {
    if (tag === 'pere') {
      this.toggleControlState(this.student.get('family').get('father'), !event.checked);
      // this.toggleControlValidity(this.student.get('family').get('father'), event.checked);
    }
    if (tag === 'mere') {
      this.toggleControlState(this.student.get('family').get('mother'), !event.checked);
      // this.toggleControlValidity(this.student.get('family').get('mother'), event.checked);
    }
  }


}
