import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { AdminComponent } from "@app/admin/admin.component";
import { AuthorizationModule } from "@authorization/authorization.module";
import { LidarMenuComponent, LidarMenuMobileComponent } from "@core/api";
import { CredentialDirective } from "@core/credential.directive";
import { LoggedUserInfoComponent } from "@shared/logged-user-info/logged-user-info.component";
import { LoggedUserComponent } from "@shared/logged-user/logged-user.component";
import { LogoButtonsComponent } from "@shared/logo-buttons/logo-buttons.component";
import { LogoComponent } from "@shared/logo/logo.component";
import { MainMenuComponent } from "@shared/main-menu/main-menu.component";
import { MenuActionComponent } from "@shared/menu-action/menu-action.component";
import { MenuAnUserComponent } from "@shared/menu-an-user/menu-an-user.component";
import { SideMenuMagicComponent } from "@shared/side-menu-magic/side-menu-magic.component";
import { ButtonModule } from "primeng/button";
import { ProgressSpinnerModule } from "primeng/progressspinner";
import { FormModule } from "src/@form/form.module";
import { AdminRoutingModule } from "./admin-routing.module";
import { DashboardModule } from "./dashboard/dashboard.module";
import { Error404Component } from "./error404/error404.component";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { PaginatorModule } from "primeng";

@NgModule({
  declarations: [
    LogoComponent,
    LoggedUserComponent,
    LoggedUserInfoComponent,
    MenuActionComponent,
    MenuAnUserComponent,
    LogoButtonsComponent,
    MainMenuComponent,
    SideMenuMagicComponent,
    AdminComponent,
    CredentialDirective,
    LidarMenuComponent,
    LidarMenuMobileComponent,
    Error404Component,
  ],
  bootstrap: [AdminComponent],
  imports: [
    CommonModule,
    FormModule,
    ReactiveFormsModule,
    AdminRoutingModule,
    AuthorizationModule,
    DashboardModule,
    ButtonModule,
    ProgressSpinnerModule,
    FormsModule,
    PaginatorModule,
    ReactiveFormsModule,
  ],
})
export class AdminModule {}
