import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DashboardComponent } from '@app/admin/dashboard/dashboard.component';
import { ButtonModule } from 'primeng/button';
import { InputTextModule } from 'primeng/inputtext';
import { ProgressSpinnerModule } from 'primeng/progressspinner';

import { AuthorizationModule } from '@authorization/authorization.module';
import { BalanceHistoryComponent } from './balance-history/balance-history.component';
import { DashboardMessageComponent } from './dashboard-message/dashboard-message.component';
import { FinancialOverviewComponent } from './financial-overview/financial-overview.component';
import { RechargementCompteMultiComponent } from './rechargement-compte-multi/rechargement-compte-multi.component';
import {RouterModule} from "@angular/router";
import {EntiteInscriptionsComponent} from "@app/admin/dashboard/entite-inscriptions/entite-inscriptions.component";
import {EntitePaiementsComponent} from "@app/admin/dashboard/entite-paiements/entite-paiements.component";
import {DropdownModule} from "primeng";


@NgModule({
  bootstrap: [DashboardComponent],
  declarations: [
    DashboardComponent,
    RechargementCompteMultiComponent,
    BalanceHistoryComponent,
    FinancialOverviewComponent,
    DashboardMessageComponent,
    EntiteInscriptionsComponent,
    EntitePaiementsComponent,
  ],
  imports: [
    CommonModule,
    AuthorizationModule,
    FormsModule,
    ReactiveFormsModule,
    InputTextModule,
    ButtonModule,
    ProgressSpinnerModule,
    RouterModule,
    DropdownModule,
  ],
  exports: [DashboardComponent],
})
export class DashboardModule {}
