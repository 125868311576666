export const environment = {
  production: false,
  multiCardId: 1,
  appVersion: require("../../package.json").version,
  ApiVersion: "v1.3",

  origin: "https://app.educonnect-platform.com",
  serverUrl: "https://api.educonnect-platform.com/v1.3",
  serverBase: "https://api.educonnect-platform.com",

  bucketUrl: "https://staticsfiles.lidar-platform.com",
  defaultLanguage: "fr-FR",
  uploadUrlEndpoint: [
    "operations/uploadfile.json",
    "users/uploadprofile.json",
    "users/upload.json",
  ],
  supportedLanguages: ["en-US", "fr-FR"],
  inactivityTime: 60 * 60 * 24,
  corporate: {
    // Modules corporates
    OBC: 1, // Opérations bord champ
    PS: 2, // Paiement des salaires
    GP: 4, // Grand public
    BP: 5, // Budgets prévisionnels
    SD: 6, // Souclou digital
  },
  salaireCat: 3,
  allowGooglePlugins: true,
};
