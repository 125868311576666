import {Component, OnDestroy, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {Observable} from "rxjs";
import {Agence} from "@interface/agence";
import {HttpParams} from "@angular/common/http";
import {AgenceService} from "@app/admin/agences/shared/services/agence.service";
import {tap} from "rxjs/operators";
import {untilDestroyed} from "ngx-take-until-destroy";
import {SelectItem} from "primeng/api";
import {Registration} from "@interface/registration";
import {RegistrationsService} from "@app/admin/souclou-digital/registrations/registrations.service";

@Component({
  selector: 'app-entite-inscriptions',
  templateUrl: './entite-inscriptions.component.html',
  styleUrls: ['./entite-inscriptions.component.css']
})
export class EntiteInscriptionsComponent implements OnInit, OnDestroy {

  form: FormGroup;
  isLoading: boolean = false;
  agenceItems: Array<SelectItem>;
  agenceAcademicYearItems: Array<SelectItem>;
  filiereItems: Array<SelectItem>;

  registrations$: Observable<Array<Registration>>;
  agences$: Observable<Array<Agence>>;
  agenceDatas: Array<Agence> = [];

  constructor(
    private fb: FormBuilder,
    private agenceService: AgenceService,
    private registrationsService: RegistrationsService,
  ) { }

  get f() { return this.form; }

  get agenceId() { return this.f.get('agence_id'); }

  get agenceAcademicYearId() { return this.f.get('agence_academic_year_id'); }

  get filiereId() { return this.f.get('filiere_id'); }

  get agences(): Observable<Agence[]> {
    return this.agenceService
      .index()
      .pipe(
        untilDestroyed(this),
        tap((agences: Array<Agence>) => {
          this.agenceDatas = agences;
          this.agenceItems = [];

          agences.forEach(agence => {
            this.agenceItems.push({
              label: agence.name.toUpperCase(),
              value: agence.id
            });
          });
        })
      );
  }

  get regisrations(): Observable<Array<Registration>> {
    const params: HttpParams = new HttpParams()
      .set('agence_academic_year_id', String(this.agenceAcademicYearId.value))
      .set('agence_id', String(this.agenceId.value))
      .set('filiere_id', String(this.filiereId.value));
    return this.registrationsService
      .getList(params)
      .pipe(
        untilDestroyed(this),
        tap(() => {
          this.isLoading = false;
        }),
      );
  }

  ngOnDestroy() {
  }

  ngOnInit() {
    this.form = this.fb.group({
      agence_id: ['', Validators.required],
      agence_academic_year_id: ['', Validators.required],
      filiere_id: [''],
    });

    this.agences$ = this.agences;

    this.filiereId.valueChanges
      .pipe(
        untilDestroyed(this),
        tap((next) => {
          if (next !== '') {
            this.registrations$ = this.regisrations;
          }
        })
      ).subscribe();

    this.agenceAcademicYearId.valueChanges
      .pipe(
        untilDestroyed(this),
        tap((next) => {
          if (next !== '') {
            this.registrations$ = this.regisrations;
          }
        })
      ).subscribe();

    this.agenceId.valueChanges
      .pipe(
        untilDestroyed(this),
        tap(next => {
          this.agenceAcademicYearId.setValue('');
          this.filiereId.setValue('');

          if (next !== '') {
            this.agenceAcademicYearItems = [];
            this.filiereItems = [];

            this.agenceDatas.forEach(agence => {
              if (next === agence.id) {
                agence.agence_academic_years.forEach(year => {
                  this.agenceAcademicYearItems.push({
                    label: year.academic_year.name.toUpperCase(),
                    value: year.id
                  })
                });
                agence.filieres.forEach(filiere => {
                  this.filiereItems.push({
                    label: filiere.name.toUpperCase(),
                    value: filiere.id
                  })
                });
              }
            });
          }
        })
      ).subscribe();
  }

  submit() {
    this.isLoading = true;
    this.registrations$ = this.regisrations;
  }

  canShowFiliere(registration: Registration): boolean {
    if (this.filiereId.value === '') {
      return true;
    }
    return registration.classroom.filiere_id === this.filiereId.value;
  }

  getTotalRegistration(registrations: Array<Registration>): number {
    let i = 0;
    registrations.forEach(registration => {
      if (this.canShowFiliere(registration)) {
        i += 1;
      }
    })
    return i;
  }

}
