<ng-container *ngIf="(agence && corporate.canAgence(agence, [env.corporate.SD])) else notSD">
  <app-lidar-menu [items]="items"></app-lidar-menu>
</ng-container>

<ng-template #notSD>
  <ul class="main-menu" routerLinkActive="has-active">

    <ng-container *canProfileAccess="[Profiles.multi]">
      <ng-container *canActionAccess="[
                EntrepriseRole.canAdd,
                EntrepriseRole.canAddProfile,
                EntrepriseRole.canChangeRespo,
                EntrepriseRole.canChangeStatut,
                EntrepriseRole.canEdit,
                EntrepriseRole.canAddProfile,
                EntrepriseRole.canSendTrash
              ]">
        <li class="sub-header">
          <span>Entreprises</span>
        </li>

        <ng-container *canActionAccess="[
                EntrepriseRole.canAdd,
                EntrepriseRole.canAddProfile,
                EntrepriseRole.canChangeRespo,
                EntrepriseRole.canChangeStatut,
                EntrepriseRole.canEdit,
                EntrepriseRole.canAddProfile,
                EntrepriseRole.canSendTrash
              ]">
          <li routerLinkActive="selected active" class="has-sub-menu">
            <a href="javascript:;">
              <div class="icon-w">
                <div class="picons-thin-icon-thin-0047_home_flat"></div>
              </div>
              <span>Entreprises</span>
            </a>
            <div class="sub-menu-w">
              <div class="sub-menu-header">Entreprises</div>
              <div class="sub-menu-icon">
                <i class="os-icon os-icon-layout"></i>
              </div>
              <div class="sub-menu-i">
                <ul class="sub-menu">
                  <li>
                    <a routerLink="/admin/entreprises" routerLinkActive="active">
                      Mes entreprises
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </li>
        </ng-container>
      </ng-container>
    </ng-container>

    <ng-container *canProfileAccess="[Profiles.entite]">
      <li class="sub-header">
        <span>Dashboard</span>
      </li>
    </ng-container>

    <ng-container *canProfileAccess="[Profiles.entite]">
      <li routerLinkActive="selected active">
        <a routerLink="/admin/dashboard/inscriptions">
          <div class="icon-w">
            <div class="fa fa-list-alt"></div>
          </div>
          <span>Inscriptions</span>
        </a>
      </li>
      <li routerLinkActive="selected active">
        <a routerLink="/admin/dashboard/paiements">
          <div class="icon-w">
            <div class="fa fa-download"></div>
          </div>
          <span>Paiements</span>
        </a>
      </li>
    </ng-container>

    <!--    <ng-container *canProfileAccess="[Profiles.multi]">-->
    <!--      <ng-container *canActionAccess="[StockRole.canReception, StockRole.canDistribution, StockRole.canRetour]">-->
    <!--        <li class="sub-header">-->
    <!--          <span>Stock de cartes</span>-->
    <!--        </li>-->
    <!--      </ng-container>-->
    <!--    </ng-container>-->

    <!--    <ng-container *canProfileAccess="[Profiles.multi]">-->
    <!--      <ng-container *canActionAccess="[-->
    <!--          StockRole.canAdd,-->
    <!--           StockRole.canDistribution,-->
    <!--           StockRole.canReception,-->
    <!--           StockRole.canRetour-->
    <!--           ]">-->
    <!--        <li routerLinkActive="selected active">-->
    <!--          <a routerLink="/admin/disponibilites">-->
    <!--            <div class="icon-w">-->
    <!--              <div class="fa fa-list-alt"></div>-->
    <!--            </div>-->
    <!--            <span>Disponibilité</span>-->
    <!--          </a>-->
    <!--        </li>-->
    <!--      </ng-container>-->
    <!--    </ng-container>-->

    <!--    <ng-container *canProfileAccess="[Profiles.entite]">-->
    <!--      <ng-container *canActionAccess="[StockRole.canReception]">-->
    <!--        <li routerLinkActive="selected active" class="has-sub-menu">-->
    <!--          <a routerLink="/admin/receptions">-->
    <!--            <div class="icon-w">-->
    <!--              <div class="fa fa-download"></div>-->
    <!--            </div>-->
    <!--            <span>Réception de cartes</span>-->
    <!--          </a>-->
    <!--          <div class="sub-menu-w">-->
    <!--            <div class="sub-menu-header">Réception de cartes</div>-->
    <!--            <div class="sub-menu-icon">-->
    <!--              <i class="os-icon os-icon-layout"></i>-->
    <!--            </div>-->
    <!--            <div class="sub-menu-i">-->
    <!--              <ul class="sub-menu">-->
    <!--                <li>-->
    <!--                  <a routerLink="/admin/receptions/add">Ajouter</a>-->
    <!--                </li>-->
    <!--              </ul>-->
    <!--            </div>-->
    <!--          </div>-->
    <!--        </li>-->
    <!--      </ng-container>-->
    <!--    </ng-container>-->

    <!--    <ng-container *canProfileAccess="[Profiles.entite]">-->
    <!--      <ng-container *canActionAccess="[StockRole.canDistribution]">-->
    <!--        <li routerLinkActive="selected active" [class]="credentialService.isEntite() ? 'has-sub-menu' : ''">-->
    <!--          <a routerLink="/admin/distributions">-->
    <!--            <div class="icon-w">-->
    <!--              <div class="fa fa-upload"></div>-->
    <!--            </div>-->
    <!--            <span>Distribution de cartes</span>-->
    <!--          </a>-->
    <!--          <ng-container *canProfileAccess="[Profiles.entite]">-->
    <!--            <div class="sub-menu-w">-->
    <!--              <div class="sub-menu-header">Distribution de cartes</div>-->
    <!--              <div class="sub-menu-icon">-->
    <!--                <i class="os-icon os-icon-layout"></i>-->
    <!--              </div>-->
    <!--              <div class="sub-menu-i">-->
    <!--                <ul class="sub-menu">-->
    <!--                  <li>-->
    <!--                    <a routerLink="/admin/distributions/add">Ajouter</a>-->
    <!--                  </li>-->
    <!--                </ul>-->
    <!--              </div>-->
    <!--            </div>-->
    <!--          </ng-container>-->
    <!--        </li>-->
    <!--      </ng-container>-->
    <!--    </ng-container>-->

    <!--
  <ng-container *canProfileAccess="[Profiles.entite]">
    <ng-container *canActionAccess="[StockRole.canRetour]">
      <li routerLinkActive="selected active" [class]="credentialService.isAgence() ? 'has-sub-menu' : ''">
        <a routerLink="/admin/retours">
          <div class="icon-w">
            <div class="fa fa-reply-all"></div>
          </div>
          <span>Retour de cartes</span>
        </a>
        <ng-container *canProfileAccess="[Profiles.agence]">
          <div class="sub-menu-w">
            <div class="sub-menu-header">Retour de cartes</div>
            <div class="sub-menu-icon">
              <i class="os-icon os-icon-layout"></i>
            </div>
            <div class="sub-menu-i">
              <ul class="sub-menu">
                <li>
                  <a routerLink="/admin/retours/add">Ajouter</a>
                </li>
              </ul>
            </div>
          </div>
        </ng-container>
      </li>
    </ng-container>
  </ng-container>
  -->

    <ng-container *canProfileAccess="[Profiles.agence]">
      <ng-container
        *ngIf="agence && corporate.canAgence(agence, [env.corporate.BP, env.corporate.GP, env.corporate.OBC, env.corporate.PS])">
        <ng-container *canActionAccess="[
      SalarieRole.canAdd,
       SalarieRole.canEdit,
       SalarieRole.canDelete,
       BeneficiaireRole.canEdit, BeneficiaireRole.canView,
       DelegueRole.canAdd, DelegueRole.canDelete,
       ProducteurRole.canAdd, ProducteurRole.canDelete]">
          <li class="sub-header">
            <span>Cartes Lidar</span>
          </li>

          <li class="has-sub-menu" routerLinkActive="selected active">
            <a href="javascipt:;">
              <div class="icon-w">
                <div class="fa fa-id-card"></div>
              </div>
              <span>Bénéficiaires</span>
            </a>

            <div class="sub-menu-w">
              <div class="sub-menu-header">Bénéficiaires</div>
              <div class="sub-menu-icon">
                <i class="fa fa-id-card"></i>
              </div>
              <div class="sub-menu-i">
                <ul class="sub-menu">

                  <ng-container *canActionAccess="[BeneficiaireRole.canEdit, BeneficiaireRole.canView]">
                    <li>
                      <a routerLink="/admin/beneficiaires">Tous les bénéficiaires</a>
                    </li>
                  </ng-container>

                  <ng-container *ngIf="agence && corporate.canAgence(agence, [env.corporate.OBC])">
                    <ng-container *canActionAccess="[DelegueRole.canAdd, DelegueRole.canDelete]">
                      <li>
                        <a routerLink="/admin/delegues">Délégués</a>
                      </li>
                    </ng-container>
                    <ng-container *canActionAccess="[ProducteurRole.canAdd, ProducteurRole.canDelete]">
                      <li>
                        <a routerLink="/admin/producteurs">Producteurs</a>
                      </li>
                    </ng-container>
                  </ng-container>

                  <ng-container *ngIf="agence && corporate.canAgence(agence, [env.corporate.PS, env.corporate.BP])">
                    <ng-container *canActionAccess="[SalarieRole.canAdd, SalarieRole.canEdit, SalarieRole.canDelete]">
                      <li><a routerLink="/admin/salaries">Salariés</a></li>
                    </ng-container>
                  </ng-container>

                  <ng-container *ngIf="agence && corporate.canAgence(agence, [env.corporate.BP])">
                    <li><a routerLink="/admin/prestataires">Prestataires</a></li>
                    <li><a routerLink="/admin/caisses-depenses">Caisses dépenses</a></li>
                  </ng-container>

                </ul>
              </div>
            </div>

          </li>
        </ng-container>
      </ng-container>
    </ng-container>

    <ng-container *canProfileAccess="[Profiles.multi, Profiles.entite, Profiles.agence]">
      <ng-container *canActionAccess="[
            AgenceRole.canAdd,
            AgenceRole.canChangeRespo,
            AgenceRole.canChangeStatut,
            AgenceRole.canEdit,
            AgenceRole.canSendTrash,
            AgenceRole.canView,
            FournisseurAgenceRole.canAdd,
            FournisseurAgenceRole.canEdit,
            FournisseurAgenceRole.canDelete,
            FournisseurRole.canList,
            DistributeurRole.canAdd,
            DistributeurRole.canChangeStatut,
            DistributeurRole.canEdit,
            DistributeurRole.canSendTrash,
            DistributeurRole.canView, AccepteurRole.canAdd,
            AccepteurRole.canChangeStatut,
            AccepteurRole.canEdit,
            AccepteurRole.canSendTrash,
            AccepteurRole.canView]">

        <ng-container *canProfileAccess="[Profiles.entite]">
          <li class="sub-header">
            <span>Comptes établissement</span>
          </li>
        </ng-container>

        <ng-container *canProfileAccess="[Profiles.agence]">
          <ng-container *ngIf="agence && corporate.canAgence(agence, [env.corporate.OBC, env.corporate.PS])">
            <li hidden class="sub-header">
              <span>Réseau de distribution</span>
            </li>
          </ng-container>
        </ng-container>

      </ng-container>
    </ng-container>

    <ng-container *canProfileAccess="[Profiles.entite]">
      <ng-container *canActionAccess="[
            AgenceRole.canAdd,
            AgenceRole.canChangeRespo,
            AgenceRole.canChangeStatut,
            AgenceRole.canEdit,
            AgenceRole.canSendTrash,
            AgenceRole.canView
          ]">
        <li routerLinkActive="selected active" class="has-sub-menu">
          <a href="javascript:;">
            <div class="icon-w">
              <div class="dashicons dashicons-admin-multisite"></div>
            </div>
            <span>&Eacute;tablissements</span>
          </a>
          <div class="sub-menu-w">
            <div class="sub-menu-header">&Eacute;tablissements</div>
            <div class="sub-menu-icon">
              <i class="os-icon os-icon-layout"></i>
            </div>
            <div class="sub-menu-i">
              <ul class="sub-menu">
                <li>
                  <a routerLink="/admin/agences">Mes établissements</a>
                </li>
              </ul>
            </div>
          </div>
        </li>
      </ng-container>
    </ng-container>

    <ng-container *canProfileAccess="[Profiles.multi, Profiles.entite, Profiles.agence]">
      <ng-container *canActionAccess="[
          OperationRole.canCreditAdd,
          OperationRole.canCreditReception,
          OperationRole.canCreditRembourse,
           OperationRole.canMandatAdd,
           OperationRole.canMandatReception,
           OperationRole.canRechargeAgence,
            OperationRole.canRechargeCoop,
           OperationRole.canRechargeDistributeur,
           OperationRole.canRechargeEntreprise,
           OperationRole.canRechargeFournisseur,
           OperationRole.canRechargeMarchand,
           OperationRole.canRechargeME,
           OperationRole.canPayPrime,
           OperationRole.canPaySalaire,
           WfRole.canReceiveSMS,
           WfRole.canValidateOp,
           BudgetPrevisionnelRole.canEntiteChangeState,
           BudgetPrevisionnelRole.canEntiteEdit
           ]">
        <li class="sub-header">
          <span>Opérations</span>
        </li>

        <li routerLinkActive="selected active"
            *canActionAccess="[
              OperationRole.canRechargeAgence,
              OperationRole.canRechargeCoop,
              OperationRole.canRechargeDistributeur,
              OperationRole.canRechargeEntreprise,
              OperationRole.canRechargeFournisseur,
              OperationRole.canRechargeMarchand,
              OperationRole.canRechargeME
            ]">
          <a routerLink="/admin/operations">
            <div class="icon-w">
              <div class="os-icon os-icon-clock"></div>
            </div>
            <span>Historique</span>
          </a>
        </li>
      </ng-container>
    </ng-container>

  </ul>
</ng-template>
