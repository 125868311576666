import { HttpClient, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { AbstractService } from "@core/api";
import { CredentialService } from "@core/credential.service";
import { Filiere } from "@interface/filiere";
import { NiveauEtude } from "@interface/niveau-etude";
import { Secteur } from "@interface/secteur";
import { AgenceAcademicYearStore } from "@lidar/stores/agence-academic-year.store";
import { Beneficiaire } from "@model/beneficiaire";
import { Classroom } from "@model/classroom";
import { ClassroomType } from "@model/classroom-type";
import { Response } from "@model/response";
import { Student } from "@model/student";
import { Teacher } from "@model/teacher";
import { Worker } from "@interface/worker";
import { Tuteur } from "@model/tuteur";
import { Job } from "@interface/job";
import { Site } from "@interface/site";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { Building } from "@interface/building";
import { Room } from "@interface/room";

@Injectable({
  providedIn: "root",
})
export class SouclouDigitalService extends AbstractService {
  constructor(
    private credentialService: CredentialService,
    private agenceAcademicYearStore: AgenceAcademicYearStore,
    private http: HttpClient
  ) {
    super();
  }

  attribuer(data: any): Observable<any> {
    this.endpoint = `/teacher_matiere_classrooms.json`;

    return this.http.post<any>(this.endpoint, data).pipe(
      map((response) => {
        if (response.code === 0) {
          return response.teacher_matiere_classroom;
        }
        throw new Error(response.errorMessage);
      })
    );
  }

  reattribuer(id: any, data: any): Observable<any> {
    this.endpoint = `/teacher_matiere_classrooms/${id}.json`;

    return this.http.put<any>(this.endpoint, data).pipe(
      map((response) => {
        if (response.code === 0) {
          return response.teacher_matiere_classroom;
        }
        throw new Error(response.errorMessage);
      })
    );
  }

  removeAttribution(id: any): Observable<boolean> {
    this.endpoint = `/teacher_matiere_classrooms/${id}.json`;

    return this.http.delete<any>(this.endpoint).pipe(
      map((response) => {
        return response.code === 0;
      })
    );
  }

  addNote(data: any): Observable<any> {
    this.endpoint = `/notes.json`;

    return this.http.post<any>(this.endpoint, data).pipe(
      map((response) => {
        if (response.code === 0) {
          return response.note;
        }
        throw new Error(response.errorMessage);
      })
    );
  }

  updateNote(id: any, data: any): Observable<any> {
    this.endpoint = `/notes/${id}.json`;

    return this.http.put<any>(this.endpoint, data).pipe(
      map((response) => {
        if (response.code === 0) {
          return response.note;
        }
        throw new Error(response.errorMessage);
      })
    );
  }

  getTeacherClassrooms(params?: HttpParams) {
    this.endpoint = `/teacher_matiere_classrooms/classrooms.json`;
    return this.http.get<any>(this.endpoint, { params });
  }

  /**
   *
   * API Filieres
   */

  getFilieres(params?: HttpParams): Observable<Filiere[]> {
    this.endpoint = `/filieres.json`;

    if (this.credentialService.isAgence()) {
      this.endpoint = `/agences/${this.credentialService.credentials.user.agence_user.agence.id}${this.endpoint}`;
    }

    return this.http.get<Response>(this.endpoint, { params }).pipe(
      map((response: Response) => {
        if (response.code === 0) {
          return response.filieres;
        }
        throw new Error(response.errorMessage);
      })
    );
  }

  getPaginateFilieres(data: any) {
    this.endpoint = `/filieres/all.json`;

    if (this.credentialService.isAgence()) {
      this.endpoint = `/agences/${this.credentialService.credentials.user.agence_user.agence.id}${this.endpoint}`;
    }

    return this.http.post<any>(this.endpoint, data);
  }

  createFiliere(data: any): Observable<Filiere> {
    this.endpoint = "/filieres.json";

    if (this.credentialService.isAgence()) {
      this.endpoint = `/agences/${this.credentialService.credentials.user.agence_user.agence.id}${this.endpoint}`;
    }

    return this.http.post<Response>(this.endpoint, data).pipe(
      map((response) => {
        if (response.code === 0) {
          return response.filiere;
        }
        throw new Error(response.errorMessage);
      })
    );
  }

  updateFiliere(data: Filiere): Observable<Filiere> {
    this.endpoint = `/filieres/${data.id}.json`;

    if (this.credentialService.isAgence()) {
      this.endpoint = `/agences/${this.credentialService.credentials.user.agence_user.agence.id}${this.endpoint}`;
    }

    return this.http.put<Response>(this.endpoint, data).pipe(
      map((response) => {
        if (response.code === 0) {
          return response.filiere;
        }
        throw new Error(response.errorMessage);
      })
    );
  }

  deleteFiliere(id: number): Observable<boolean> {
    this.endpoint = `/filieres/${id}.json`;

    if (this.credentialService.isAgence()) {
      this.endpoint = `/agences/${this.credentialService.credentials.user.agence_user.agence.id}${this.endpoint}`;
    }

    return this.http.delete<Response>(this.endpoint).pipe(
      map((response) => {
        return response.code === 0;
      })
    );
  }

  /**
   *
   * API Niveaux d'étude
   */

  getAllNiveauEtude(params?: HttpParams) {
    this.endpoint = `/niveau_etudes/getAll.json`;

    return this.http.get<Response>(this.endpoint, { params });
  }

  getPaginateNiveauxEtude(data: any) {
    this.endpoint = `/niveau_etudes/all.json`;

    return this.http.post<any>(this.endpoint, data);
  }

  createNiveauEtude(data: any): Observable<NiveauEtude> {
    this.endpoint = `/niveau_etudes.json`;

    return this.http.post<Response>(this.endpoint, data).pipe(
      map((response) => {
        if (response.code === 0) {
          return response.niveau_etude;
        }
        throw new Error(response.errorMessage);
      })
    );
  }

  createManyNiveauEtude(data: any): Observable<any> {
    this.endpoint = `/niveau_etudes/addMany.json`;

    return this.http.post<Response>(this.endpoint, data).pipe(
      map((response) => {
        if (response.code === 0) {
          return response.niveau_etudes;
        }
        throw new Error(response.errorMessage);
      })
    );
  }

  updateNiveauEtude(data: NiveauEtude): Observable<NiveauEtude> {
    this.endpoint = `/niveau_etudes/${data.id}.json`;

    return this.http.put<Response>(this.endpoint, data).pipe(
      map((response) => {
        if (response.code === 0) {
          return response.niveau_etude;
        }
        throw new Error(response.errorMessage);
      })
    );
  }

  deleteNiveauEtude(id: number): Observable<boolean> {
    this.endpoint = `/niveau_etudes/${id}.json`;

    return this.http.delete<Response>(this.endpoint).pipe(
      map((response) => {
        return response.code === 0;
      })
    );
  }

  /**
   *
   * API Secteurs d'étude
   */

  getSecteurs(params?: HttpParams): Observable<any[]> {
    this.endpoint = `/secteurs.json`;
    return this.http.get<Response>(this.endpoint, { params }).pipe(
      map((response: Response) => {
        if (response.code === 0) {
          return response.secteurs;
        }
        throw new Error(response.errorMessage);
      })
    );
  }

  getListSecteurs(params?: HttpParams): Observable<any[]> {
    this.endpoint = `/secteurs/list.json`;
    return this.http.get<Response>(this.endpoint, { params }).pipe(
      map((response: Response) => {
        if (response.code === 0) {
          return response.secteurs;
        }
        throw new Error(response.errorMessage);
      })
    );
  }

  getPaginateSecteurs(data: any) {
    this.endpoint = `/secteurs/all.json`;

    return this.http.post<any>(this.endpoint, data);
  }

  readSecteur(id: number): Observable<Secteur> {
    this.endpoint = `/secteurs/${id}.json`;

    return this.http.get<Response>(this.endpoint).pipe(
      map((response) => {
        if (response.code === 0) {
          return response.secteur;
        }
        throw new Error(response.errorMessage);
      })
    );
  }

  createSecteur(data: any): Observable<Secteur> {
    this.endpoint = `/secteurs.json`;

    return this.http.post<Response>(this.endpoint, data).pipe(
      map((response) => {
        if (response.code === 0) {
          return response.secteur;
        }
        throw new Error(response.errorMessage);
      })
    );
  }

  updateSecteur(data: Secteur): Observable<Secteur> {
    this.endpoint = `/secteurs/${data.id}.json`;

    return this.http.put<Response>(this.endpoint, data).pipe(
      map((response) => {
        if (response.code === 0) {
          return response.secteur;
        }
        throw new Error(response.errorMessage);
      })
    );
  }

  deleteSecteur(id: number): Observable<boolean> {
    this.endpoint = `/secteurs/${id}.json`;

    return this.http.delete<Response>(this.endpoint).pipe(
      map((response) => {
        return response.code === 0;
      })
    );
  }

  getAcademicYears(params?: HttpParams): Observable<any[]> {
    this.endpoint = `/academic_years.json`;

    return this.http.get<Response>(this.endpoint, { params }).pipe(
      map((response: Response) => {
        if (response.code === 0) {
          return response.academic_years;
        }
        throw new Error(response.errorMessage);
      })
    );
  }

  getAgenceAcademicYears(
    params?: HttpParams,
    isOut: boolean = false,
    end_point_Out: any = null
  ): Observable<any[]> {
    if (isOut == false) {
      this.endpoint = `/agences/${this.credentialService.credentials.user.agence_user.agence.id}/agence_academic_years/list.json`;
    } else {
      this.endpoint = end_point_Out;
    }
    return this.http.get<Response>(this.endpoint, { params }).pipe(
      map((response: Response) => {
        if (response.code === 0) {
          return response.agence_academic_years;
        }
        throw new Error(response.errorMessage);
      })
    );
  }

  getAgenceAcademicYearsDetail(params?: HttpParams, id?: any): Observable<any> {
    this.endpoint = `/agence_academic_years/${id}.json`;
    return this.http.get<Response>(this.endpoint, { params }).pipe(
      map((response: Response) => {
        if (response.code === 0) {
          return response;
        }
        throw new Error(response.errorMessage);
      })
    );
  }

  getClassroomTypes(params?: HttpParams): Observable<ClassroomType[]> {
    this.endpoint = `/classroom_types.json`;

    return this.http.get<Response>(this.endpoint, { params }).pipe(
      map((response: Response) => {
        if (response.code === 0) {
          return response.classroom_types;
        }
        throw new Error(response.errorMessage);
      })
    );
  }

  getBeneficiaires(params?: HttpParams): Observable<Beneficiaire[]> {
    this.endpoint = `/beneficiaires.json`;

    if (this.credentialService.isEntite()) {
      this.endpoint = `/entites/${this.credentialService.credentials.user.entite_user.entite.id}${this.endpoint}`;
    }

    if (this.credentialService.isAgence()) {
      this.endpoint = `/agences/${this.credentialService.credentials.user.agence_user.agence.id}${this.endpoint}`;
    }

    return this.http.get<Response>(this.endpoint, { params }).pipe(
      map((response: Response) => {
        if (response.code === 0) {
          return response.beneficiaires;
        }
        throw new Error(response.errorMessage);
      })
    );
  }

  // Students service

  getStudents(params?: HttpParams): Observable<Student[]> {
    this.endpoint = `/students.json`;

    if (this.credentialService.isEntite()) {
      this.endpoint = `/entites/${this.credentialService.credentials.user.entite_user.entite.id}${this.endpoint}`;
    }

    if (this.credentialService.isAgence()) {
      this.endpoint = `/agences/${this.credentialService.credentials.user.agence_user.agence.id}${this.endpoint}`;
    }

    return this.http.get<Response>(this.endpoint, { params }).pipe(
      map((response: Response) => {
        if (response.code === 0) {
          return response.students;
        }
        throw new Error(response.errorMessage);
      })
    );
  }

  getPaginateStudents(data: any) {
    this.endpoint = `/students/all.json`;

    if (this.credentialService.isEntite()) {
      this.endpoint = `/entites/${this.credentialService.credentials.user.entite_user.entite.id}${this.endpoint}`;
    }

    if (this.credentialService.isAgence()) {
      this.endpoint = `/agences/${this.credentialService.credentials.user.agence_user.agence.id}${this.endpoint}`;
    }

    return this.http.post<any>(this.endpoint, data);
  }

  createStudent(data: any): Observable<Student> {
    this.endpoint = `/students.json`;

    return this.http.post<Response>(this.endpoint, data).pipe(
      map((response) => {
        if (response.code === 0) {
          return response.student;
        }
        throw new Error(response.errorMessage);
      })
    );
  }

  updateStudent(id: number, data: any): Observable<Student> {
    this.endpoint = `/students/${id}.json`;

    return this.http.put<Response>(this.endpoint, data).pipe(
      map((response) => {
        if (response.code === 0) {
          return response.student;
        }
        throw new Error(response.errorMessage);
      })
    );
  }

  readStudent(id: number): Observable<Student> {
    this.endpoint = `/students/${id}.json`;

    return this.http.get<Response>(this.endpoint).pipe(
      map((response) => {
        if (response.code === 0) {
          return response.student;
        }
        throw new Error(response.errorMessage);
      })
    );
  }

  // retrieveStudent(agence_academic_year_id: number, classe: string, nom: string, prenoms?: string): Observable<Student> {
  //   this.endpoint = `/students/${id}.json`;

  //   return this.http
  //     .get<Response>(this.endpoint)
  //     .pipe(
  //       map((response) => {
  //         if (response.code === 0) {
  //           return response.student;
  //         }
  //         throw new Error(response.errorMessage);
  //       })
  //     );
  // }

  // Tuteurs service

  getPaginateParents(data: any) {
    this.endpoint = `/tuteurs/all.json`;

    if (this.credentialService.isEntite()) {
      this.endpoint = `/entites/${this.credentialService.credentials.user.entite_user.entite.id}${this.endpoint}`;
    }

    if (this.credentialService.isAgence()) {
      this.endpoint = `/agences/${this.credentialService.credentials.user.agence_user.agence.id}${this.endpoint}`;
    }

    return this.http.post<any>(this.endpoint, data);
  }

  createTuteur(data: any): Observable<Tuteur> {
    this.endpoint = `/tuteurs.json`;

    return this.http.post<Response>(this.endpoint, data).pipe(
      map((response) => {
        if (response.code === 0) {
          return response.tuteur;
        }
        throw new Error(response.errorMessage);
      })
    );
  }

  updateTuteur(id: number, data: any): Observable<Tuteur> {
    this.endpoint = `/tuteurs/${id}.json`;

    return this.http.put<Response>(this.endpoint, data).pipe(
      map((response) => {
        if (response.code === 0) {
          return response.tuteur;
        }
        throw new Error(response.errorMessage);
      })
    );
  }

  readTuteur(id: number): Observable<Tuteur> {
    this.endpoint = `/tuteurs/${id}.json`;

    return this.http.get<Response>(this.endpoint).pipe(
      map((response) => {
        if (response.code === 0) {
          return response.tuteur;
        }
        throw new Error(response.errorMessage);
      })
    );
  }

  removeTuteur(id: number): Observable<boolean> {
    this.endpoint = `/tuteurs/${id}.json`;

    return this.http.delete<Response>(this.endpoint).pipe(
      map((response) => {
        return response.code === 0;
      })
    );
  }

  /**
   *
   * API Jobs
   */

  getJobs(params?: HttpParams): Observable<Job[]> {
    this.endpoint = `/jobs.json`;

    if (this.credentialService.isAgence()) {
      this.endpoint = `/agences/${this.credentialService.credentials.user.agence_user.agence.id}${this.endpoint}`;
    }

    return this.http.get<Response>(this.endpoint, { params }).pipe(
      map((response: Response) => {
        if (response.code === 0) {
          return response.jobs;
        }
        throw new Error(response.errorMessage);
      })
    );
  }

  getPaginateJobs(data: any) {
    this.endpoint = `/jobs/all.json`;

    if (this.credentialService.isAgence()) {
      this.endpoint = `/agences/${this.credentialService.credentials.user.agence_user.agence.id}${this.endpoint}`;
    }

    return this.http.post<any>(this.endpoint, data);
  }

  createJob(data: any): Observable<Job> {
    this.endpoint = "/jobs.json";

    if (this.credentialService.isAgence()) {
      this.endpoint = `/agences/${this.credentialService.credentials.user.agence_user.agence.id}${this.endpoint}`;
    }

    return this.http.post<Response>(this.endpoint, data).pipe(
      map((response) => {
        if (response.code === 0) {
          return response.job;
        }
        throw new Error(response.errorMessage);
      })
    );
  }

  updateJob(data: Job): Observable<Job> {
    this.endpoint = `/jobs/${data.id}.json`;

    if (this.credentialService.isAgence()) {
      this.endpoint = `/agences/${this.credentialService.credentials.user.agence_user.agence.id}${this.endpoint}`;
    }

    return this.http.put<Response>(this.endpoint, data).pipe(
      map((response) => {
        if (response.code === 0) {
          return response.job;
        }
        throw new Error(response.errorMessage);
      })
    );
  }

  deleteJob(id: number): Observable<boolean> {
    this.endpoint = `/jobs/${id}.json`;

    if (this.credentialService.isAgence()) {
      this.endpoint = `/agences/${this.credentialService.credentials.user.agence_user.agence.id}${this.endpoint}`;
    }

    return this.http.delete<Response>(this.endpoint).pipe(
      map((response) => {
        return response.code === 0;
      })
    );
  }

  /**
   *
   * API Sites
   */

  getSiteInfo(id: string): Observable<Site> {
    this.endpoint = `/sites/${id}.json`;

    if (this.credentialService.isAgence()) {
      this.endpoint = `/agences/${this.credentialService.credentials.user.agence_user.agence.id}${this.endpoint}`;
    }

    return this.http
      .get<Response>(this.endpoint)
      .pipe(map((response) => response.site));
  }

  getSites(params?: HttpParams): Observable<Site[]> {
    this.endpoint = `/sites.json`;

    if (this.credentialService.isAgence()) {
      this.endpoint = `/agences/${this.credentialService.credentials.user.agence_user.agence.id}${this.endpoint}`;
    }

    return this.http.get<Response>(this.endpoint, { params }).pipe(
      map((response: Response) => {
        if (response.code === 0) {
          return response.sites;
        }
        throw new Error(response.errorMessage);
      })
    );
  }

  getPaginateSites(data: any) {
    this.endpoint = `/sites/all.json`;

    if (this.credentialService.isAgence()) {
      this.endpoint = `/agences/${this.credentialService.credentials.user.agence_user.agence.id}${this.endpoint}`;
    }

    return this.http.post<any>(this.endpoint, data);
  }

  createSite(data: any): Observable<Site> {
    this.endpoint = "/sites.json";

    if (this.credentialService.isAgence()) {
      this.endpoint = `/agences/${this.credentialService.credentials.user.agence_user.agence.id}${this.endpoint}`;
    }

    return this.http.post<Response>(this.endpoint, data).pipe(
      map((response) => {
        if (response.code === 0) {
          return response.site;
        }
        throw new Error(response.errorMessage);
      })
    );
  }

  createSiteRoom(data: any): Observable<Site> {
    this.endpoint = "/sites/rooms.json";

    if (this.credentialService.isAgence()) {
      this.endpoint = `/agences/${this.credentialService.credentials.user.agence_user.agence.id}${this.endpoint}`;
    }

    return this.http.post<Response>(this.endpoint, data).pipe(
      map((response) => {
        if (response.code === 0) {
          return response.site;
        }
        throw new Error(response.errorMessage);
      })
    );
  }

  updateSite(id: string, data: any): Observable<Site> {
    this.endpoint = `/sites/${id}.json`;

    if (this.credentialService.isAgence()) {
      this.endpoint = `/agences/${this.credentialService.credentials.user.agence_user.agence.id}${this.endpoint}`;
    }

    return this.http.put<Response>(this.endpoint, data).pipe(
      map((response) => {
        if (response.code === 0) {
          return response.site;
        }
        throw new Error(response.errorMessage);
      })
    );
  }

  deleteSite(id: number): Observable<boolean> {
    this.endpoint = `/sites/${id}.json`;

    if (this.credentialService.isAgence()) {
      this.endpoint = `/agences/${this.credentialService.credentials.user.agence_user.agence.id}${this.endpoint}`;
    }

    return this.http.delete<Response>(this.endpoint).pipe(
      map((response) => {
        return response.code === 0;
      })
    );
  }

  deleteSiteRoom(id: number): Observable<boolean> {
    this.endpoint = `/sites/rooms/${id}.json`;

    if (this.credentialService.isAgence()) {
      this.endpoint = `/agences/${this.credentialService.credentials.user.agence_user.agence.id}${this.endpoint}`;
    }

    return this.http.delete<Response>(this.endpoint).pipe(
      map((response) => {
        return response.code === 0;
      })
    );
  }

  getPaginateBuildings(data: any) {
    this.endpoint = `/buildings/all.json`;

    if (this.credentialService.isAgence()) {
      this.endpoint = `/agences/${this.credentialService.credentials.user.agence_user.agence.id}${this.endpoint}`;
    }

    return this.http.post<any>(this.endpoint, data);
  }

  getBuildings(params?: HttpParams): Observable<Building[]> {
    this.endpoint = `/buildings.json`;

    if (this.credentialService.isAgence()) {
      this.endpoint = `/agences/${this.credentialService.credentials.user.agence_user.agence.id}${this.endpoint}`;
    }

    return this.http.get<Response>(this.endpoint, { params }).pipe(
      map((response: Response) => {
        if (response.code === 0) {
          return response.buildings;
        }
        throw new Error(response.errorMessage);
      })
    );
  }

  createBuilding(data: any): Observable<Building> {
    this.endpoint = "/buildings.json";

    if (this.credentialService.isAgence()) {
      this.endpoint = `/agences/${this.credentialService.credentials.user.agence_user.agence.id}${this.endpoint}`;
    }

    return this.http.post<Response>(this.endpoint, data).pipe(
      map((response) => {
        if (response.code === 0) {
          return response.building;
        }
        throw new Error(response.errorMessage);
      })
    );
  }

  deleteBuilding(id: number): Observable<boolean> {
    this.endpoint = `/buildings/${id}.json`;

    if (this.credentialService.isAgence()) {
      this.endpoint = `/agences/${this.credentialService.credentials.user.agence_user.agence.id}${this.endpoint}`;
    }

    return this.http.delete<Response>(this.endpoint).pipe(
      map((response) => {
        return response.code === 0;
      })
    );
  }

  updateBuilding(id: string, data: any): Observable<Building> {
    this.endpoint = `/buildings/${id}.json`;

    if (this.credentialService.isAgence()) {
      this.endpoint = `/agences/${this.credentialService.credentials.user.agence_user.agence.id}${this.endpoint}`;
    }

    return this.http
      .put<Response>(this.endpoint, data)
      .pipe(map((response) => response.building));
  }

  getBuildingInfo(id: string): Observable<Building> {
    this.endpoint = `/buildings/${id}.json`;

    if (this.credentialService.isAgence()) {
      this.endpoint = `/agences/${this.credentialService.credentials.user.agence_user.agence.id}${this.endpoint}`;
    }

    return this.http
      .get<Response>(this.endpoint)
      .pipe(map((response) => response.building));
  }

  getRooms(params?: HttpParams): Observable<Room[]> {
    this.endpoint = `/rooms.json`;

    if (this.credentialService.isAgence()) {
      this.endpoint = `/agences/${this.credentialService.credentials.user.agence_user.agence.id}${this.endpoint}`;
    }

    return this.http.get<Response>(this.endpoint, { params }).pipe(
      map((response: Response) => {
        if (response.code === 0) {
          return response.rooms;
        }
        throw new Error(response.errorMessage);
      })
    );
  }

  getPaginateRooms(data: any) {
    this.endpoint = `/rooms/all.json`;

    if (this.credentialService.isAgence()) {
      this.endpoint = `/agences/${this.credentialService.credentials.user.agence_user.agence.id}${this.endpoint}`;
    }

    return this.http.post<any>(this.endpoint, data);
  }

  getRoomInfo(id: string): Observable<Room> {
    this.endpoint = `/rooms/${id}.json`;

    if (this.credentialService.isAgence()) {
      this.endpoint = `/agences/${this.credentialService.credentials.user.agence_user.agence.id}${this.endpoint}`;
    }

    return this.http
      .get<Response>(this.endpoint)
      .pipe(map((response) => response.room));
  }

  createRoom(data: any): Observable<Room> {
    this.endpoint = "/rooms.json";

    if (this.credentialService.isAgence()) {
      this.endpoint = `/agences/${this.credentialService.credentials.user.agence_user.agence.id}${this.endpoint}`;
    }

    return this.http.post<Response>(this.endpoint, data).pipe(
      map((response) => {
        if (response.code === 0) {
          return response.room;
        }
        throw new Error(response.errorMessage);
      })
    );
  }

  deleteRoom(id: number): Observable<boolean> {
    this.endpoint = `/rooms/${id}.json`;

    if (this.credentialService.isAgence()) {
      this.endpoint = `/agences/${this.credentialService.credentials.user.agence_user.agence.id}${this.endpoint}`;
    }

    return this.http.delete<Response>(this.endpoint).pipe(
      map((response) => {
        return response.code === 0;
      })
    );
  }

  updateRoom(id: string, data: any): Observable<Room> {
    this.endpoint = `/rooms/${id}.json`;

    if (this.credentialService.isAgence()) {
      this.endpoint = `/agences/${this.credentialService.credentials.user.agence_user.agence.id}${this.endpoint}`;
    }

    return this.http
      .put<Response>(this.endpoint, data)
      .pipe(map((response) => response.room));
  }

  // Teachers service

  getTeachers(params: any): Observable<Teacher[]> {
    this.endpoint = `/teachers.json`;

    if (this.credentialService.isAgence()) {
      this.endpoint = `/agences/${this.credentialService.credentials.user.agence_user.agence.id}${this.endpoint}`;
    }

    return this.http.get<Response>(this.endpoint, { params }).pipe(
      map((response: Response) => {
        if (response.code > 0) {
          throw new Error(response.errorMessage);
        }
        return response.teachers;
      })
    );
  }

  getPaginateTeachers(data: any) {
    this.endpoint = `/teachers/all.json`;

    if (this.credentialService.isEntite()) {
      this.endpoint = `/entites/${this.credentialService.credentials.user.entite_user.entite.id}${this.endpoint}`;
    }

    if (this.credentialService.isAgence()) {
      this.endpoint = `/agences/${this.credentialService.credentials.user.agence_user.agence.id}${this.endpoint}`;
    }

    return this.http.post<any>(this.endpoint, data);
  }

  getPaginateTeachersallbyannee(data: any) {
    this.endpoint = `/teachers/allbyannee.json`;

    if (this.credentialService.isEntite()) {
      this.endpoint = `/entites/${this.credentialService.credentials.user.entite_user.entite.id}${this.endpoint}`;
    }

    if (this.credentialService.isAgence()) {
      this.endpoint = `/agences/${this.credentialService.credentials.user.agence_user.agence.id}${this.endpoint}`;
    }

    return this.http.post<any>(this.endpoint, data);
  }

  addReconductiton(data: any) {
    this.endpoint = `/teachers/reconduction.json`;

    if (this.credentialService.isEntite()) {
      this.endpoint = `/entites/${this.credentialService.credentials.user.entite_user.entite.id}${this.endpoint}`;
    }

    if (this.credentialService.isAgence()) {
      this.endpoint = `/agences/${this.credentialService.credentials.user.agence_user.agence.id}${this.endpoint}`;
    }

    return this.http.post<any>(this.endpoint, data);
  }

  addReconductitonPersonnnel(data: any) {
    this.endpoint = `/workers/reconduction.json`;

    if (this.credentialService.isEntite()) {
      this.endpoint = `/entites/${this.credentialService.credentials.user.entite_user.entite.id}${this.endpoint}`;
    }

    if (this.credentialService.isAgence()) {
      this.endpoint = `/agences/${this.credentialService.credentials.user.agence_user.agence.id}${this.endpoint}`;
    }
    // data.agence_academic_year_id=this.credentialService.credentials.user.user_agence_academic_year.agence_academic_year.id;

    return this.http.post<any>(this.endpoint, data);
  }

  createTeacher(data: any): Observable<Teacher> {
    this.endpoint = `/teachers.json`;

    if (this.credentialService.isAgence()) {
      this.endpoint = `/agences/${this.credentialService.credentials.user.agence_user.agence.id}${this.endpoint}`;
    }

    return this.http.post<Response>(this.endpoint, data).pipe(
      map((response) => {
        if (response.code === 0) {
          return response.teacher;
        }
        throw new Error(response.errorMessage);
      })
    );
  }

  findTeacher(data: any): Observable<Teacher[]> {
    this.endpoint = `/teachers/viewByPhone.json`;

    return this.http.post<Response>(this.endpoint, data).pipe(
      map((response) => {
        if (response.code === 0) {
          return response.teachers;
        }
        throw new Error(response.errorMessage);
      })
    );
  }

  enrolTeacher(data: any): Observable<boolean> {
    this.endpoint = `/teachers/enrolTeacher.json`;

    return this.http.post<Response>(this.endpoint, data).pipe(
      map((response) => {
        if (response.code === 0) {
          return true;
        }
        throw new Error(response.errorMessage);
      })
    );
  }

  updateTeacher(id: number, data: any): Observable<Teacher> {
    this.endpoint = `/teachers/${id}.json`;

    return this.http.put<Response>(this.endpoint, data).pipe(
      map((response) => {
        if (response.code === 0) {
          return response.teacher;
        }
        throw new Error(response.errorMessage);
      })
    );
  }

  readTeacher(id: number, params?: HttpParams): Observable<Teacher> {
    this.endpoint = `/teachers/${id}.json`;

    return this.http.get<Response>(this.endpoint, { params }).pipe(
      map((response) => {
        if (response.code === 0) {
          return response.teacher;
        }
        throw new Error(response.errorMessage);
      })
    );
  }

  removeTeacher(id: number): Observable<boolean> {
    this.endpoint = `/teachers/${id}.json`;

    if (this.credentialService.isAgence()) {
      this.endpoint = `/agences/${this.credentialService.credentials.user.agence_user.agence.id}${this.endpoint}`;
    }

    return this.http.delete<Response>(this.endpoint).pipe(
      map((response) => {
        if (response.code === 0) {
          return true;
        }
        throw new Error(response.errorMessage);
      })
    );
  }

  removeTeacherMatieres(id: number): Observable<boolean> {
    this.endpoint = `/teacher_matieres/${id}.json`;

    return this.http.delete<Response>(this.endpoint).pipe(
      map((response) => {
        if (response.code === 0) {
          return true;
        }
        throw new Error(response.errorMessage);
      })
    );
  }

  // Workers Service
  getWorkers(params: any): Observable<Worker[]> {
    this.endpoint = `/workers.json`;

    if (this.credentialService.isAgence()) {
      this.endpoint = `/agences/${this.credentialService.credentials.user.agence_user.agence.id}${this.endpoint}`;
    }

    return this.http.get<Response>(this.endpoint, { params }).pipe(
      map((response: Response) => {
        if (response.code > 0) {
          throw new Error(response.errorMessage);
        }
        return response.workers;
      })
    );
  }

  getPaginateWorkers(data: any) {
    this.endpoint = `/workers/all.json`;

    if (this.credentialService.isEntite()) {
      this.endpoint = `/entites/${this.credentialService.credentials.user.entite_user.entite.id}${this.endpoint}`;
    }

    if (this.credentialService.isAgence()) {
      this.endpoint = `/agences/${this.credentialService.credentials.user.agence_user.agence.id}${this.endpoint}`;
    }
    data.agence_academic_year_id =
      this.credentialService.credentials.user.user_agence_academic_year.agence_academic_year.id;

    return this.http.post<any>(this.endpoint, data);
  }

  getPaginateWorkersWith(data: any) {
    this.endpoint = `/workers/all.json`;

    if (this.credentialService.isEntite()) {
      this.endpoint = `/entites/${this.credentialService.credentials.user.entite_user.entite.id}${this.endpoint}`;
    }

    if (this.credentialService.isAgence()) {
      this.endpoint = `/agences/${this.credentialService.credentials.user.agence_user.agence.id}${this.endpoint}`;
    }
    // data.agence_academic_year_id=this.credentialService.credentials.user.user_agence_academic_year.agence_academic_year.id;

    return this.http.post<any>(this.endpoint, data);
  }

  createWorker(data: any): Observable<Worker> {
    this.endpoint = `/workers.json`;

    if (this.credentialService.isAgence()) {
      this.endpoint = `/agences/${this.credentialService.credentials.user.agence_user.agence.id}${this.endpoint}`;
    }
    data.agence_academic_year_id =
      this.credentialService.credentials.user.user_agence_academic_year.agence_academic_year.id;
    return this.http.post<Response>(this.endpoint, data).pipe(
      map((response) => {
        if (response.code === 0) {
          return response.worker;
        }
        throw new Error(response.errorMessage);
      })
    );
  }

  findWorker(data: any): Observable<Worker[]> {
    this.endpoint = `/workers/viewByPhone.json`;

    if (this.credentialService.isAgence()) {
      this.endpoint = `/agences/${this.credentialService.credentials.user.agence_user.agence.id}${this.endpoint}`;
    }

    return this.http.post<Response>(this.endpoint, data).pipe(
      map((response) => {
        if (response.code === 0) {
          return response.workers;
        }
        throw new Error(response.errorMessage);
      })
    );
  }

  enrolWorker(data: any): Observable<boolean> {
    this.endpoint = `/workers/enrolWorker.json`;

    if (this.credentialService.isAgence()) {
      this.endpoint = `/agences/${this.credentialService.credentials.user.agence_user.agence.id}${this.endpoint}`;
    }

    return this.http.post<Response>(this.endpoint, data).pipe(
      map((response) => {
        if (response.code === 0) {
          return true;
        }
        throw new Error(response.errorMessage);
      })
    );
  }

  updateWorker(id: number, data: any): Observable<Worker> {
    this.endpoint = `/workers/${id}.json`;

    if (this.credentialService.isAgence()) {
      this.endpoint = `/agences/${this.credentialService.credentials.user.agence_user.agence.id}${this.endpoint}`;
    }

    return this.http.put<Response>(this.endpoint, data).pipe(
      map((response) => {
        if (response.code === 0) {
          return response.worker;
        }
        throw new Error(response.errorMessage);
      })
    );
  }

  readWorker(id: number, params?: HttpParams): Observable<Worker> {
    this.endpoint = `/workers/${id}.json`;

    if (this.credentialService.isAgence()) {
      this.endpoint = `/agences/${this.credentialService.credentials.user.agence_user.agence.id}${this.endpoint}`;
    }

    return this.http.get<Response>(this.endpoint, { params }).pipe(
      map((response) => {
        if (response.code === 0) {
          return response.worker;
        }
        throw new Error(response.errorMessage);
      })
    );
  }

  removeWorker(id: number): Observable<boolean> {
    this.endpoint = `/workers/${id}.json`;

    if (this.credentialService.isAgence()) {
      this.endpoint = `/agences/${this.credentialService.credentials.user.agence_user.agence.id}${this.endpoint}`;
    }

    return this.http.delete<Response>(this.endpoint).pipe(
      map((response) => {
        if (response.code === 0) {
          return true;
        }
        throw new Error(response.errorMessage);
      })
    );
  }

  // Classrooms service

  getPaginateClassrooms(data: any) {
    this.endpoint = `/classrooms/all.json`;

    if (this.credentialService.isEntite()) {
      this.endpoint = `/entites/${this.credentialService.credentials.user.entite_user.entite.id}${this.endpoint}`;
    }

    if (this.credentialService.isAgence()) {
      this.endpoint = `/agences/${this.credentialService.credentials.user.agence_user.agence.id}${this.endpoint}`;
    }

    return this.http.post<any>(this.endpoint, data);
  }

  getClassrooms(params?: HttpParams): Observable<Classroom[]> {
    this.endpoint = `/classrooms.json`;

    if (this.credentialService.isEntite()) {
      this.endpoint = `/entites/${this.credentialService.credentials.user.entite_user.entite.id}${this.endpoint}`;
    }

    if (this.credentialService.isAgence()) {
      this.endpoint = `/agences/${this.credentialService.credentials.user.agence_user.agence.id}${this.endpoint}`;
    }

    return this.http.get<Response>(this.endpoint, { params }).pipe(
      map((response) => {
        if (response.code === 0) {
          return response.classrooms;
        }
        throw new Error(response.errorMessage);
      })
    );
  }

  getClassroomsOut(
    params?: HttpParams,
    id_agence?: any
  ): Observable<Classroom[]> {
    this.endpoint = `/classrooms.json`;

    this.endpoint = `/agences/${id_agence}${this.endpoint}`;

    return this.http.get<Response>(this.endpoint, { params }).pipe(
      map((response) => {
        if (response.code === 0) {
          return response.classrooms;
        }
        throw new Error(response.errorMessage);
      })
    );
  }

  createClassroom(data: any): Observable<Classroom> {
    this.endpoint = `/classrooms.json`;

    return this.http.post<Response>(this.endpoint, data).pipe(
      map((response) => {
        if (response.code === 0) {
          return response.classroom;
        }
        throw new Error(response.errorMessage);
      })
    );
  }

  updateClassroom(id: number, data: any): Observable<Classroom> {
    this.endpoint = `/classrooms/${id}.json`;

    return this.http.put<Response>(this.endpoint, data).pipe(
      map((response) => {
        if (response.code === 0) {
          return response.classroom;
        }
        throw new Error(response.errorMessage);
      })
    );
  }

  readClassroom(id: number, params?: HttpParams): Observable<Classroom> {
    this.endpoint = `/classrooms/${id}.json`;

    if (this.credentialService.isAgence()) {
      this.endpoint = `/agences/${this.credentialService.credentials.user.agence_user.agence.id}${this.endpoint}`;
    }

    if (
      (params === null || params === undefined) &&
      this.agenceAcademicYearStore.state.active.id
    ) {
      params = new HttpParams().set(
        "agence_academic_year_id",
        String(this.agenceAcademicYearStore.state.active.id)
      );
    }

    return this.http.get<Response>(this.endpoint, { params }).pipe(
      map((response) => {
        if (response.code === 0) {
          return response.classroom;
        }
        throw new Error(response.errorMessage);
      })
    );
  }

  printBulletins(
    classroom_id: number,
    periode_id: number,
    params?: HttpParams
  ): Observable<any> {
    this.endpoint = `/moyennes/printBuilletin.json`;

    if (this.credentialService.isAgence()) {
      this.endpoint = `${this.endpoint}`;
    }

    if (
      (params === null || params === undefined) &&
      this.agenceAcademicYearStore.state.active.id
    ) {
      params = new HttpParams()
        .set(
          "agence_academic_year_id",
          String(this.agenceAcademicYearStore.state.active.id)
        )
        .set(
          "agence_id",
          String(this.credentialService.credentials.user.agence_user.agence.id)
        )
        .set("classroom_id", String(classroom_id))
        .set("periode_id", String(periode_id));
    }

    return this.http
      .get<Response>(this.endpoint, {
        params,
        headers: {
          Accept: "application/pdf",
        },
      })
      .pipe(
        map((response) => {
          return response;
        })
      );
  }

  printBulletin(
    registration_id: number,
    periode_id: number,
    params?: HttpParams
  ): Observable<any> {
    this.endpoint = "/moyennes/printBuilletin.json";

    if (this.credentialService.isAgence()) {
      this.endpoint = `${this.endpoint}`;
    }

    if (
      (params === null || params === undefined) &&
      this.agenceAcademicYearStore.state.active.id
    ) {
      params = new HttpParams()
        .set(
          "agence_academic_year_id",
          String(this.agenceAcademicYearStore.state.active.id)
        )
        .set(
          "agence_id",
          String(this.credentialService.credentials.user.agence_user.agence.id)
        )
        .set("registration_id", String(registration_id))
        .set("periode_id", String(periode_id));
    }

    return this.http
      .get<Response>(this.endpoint, {
        params,
        headers: {
          Accept: "application/pdf",
        },
      })
      .pipe(
        map((response) => {
          return response;
        })
      );
  }

  readClassroomMatieres(id: number): Observable<Classroom> {
    this.endpoint = `/classrooms/matieres.json`;
    let params;

    if (this.credentialService.isAgence()) {
      this.endpoint = `/agences/${this.credentialService.credentials.user.agence_user.agence.id}${this.endpoint}`;
    }

    if (this.agenceAcademicYearStore.state.active.id) {
      params = new HttpParams()
        .set(
          "agence_academic_year_id",
          String(this.agenceAcademicYearStore.state.active.id)
        )
        .set("classroom_id", String(id));
    }

    return this.http.get<Response>(this.endpoint, { params }).pipe(
      map((response) => {
        if (response.code === 0) {
          return response.classroom;
        }
        throw new Error(response.errorMessage);
      })
    );
  }

  cancelPayment(data: any): Observable<boolean> {
    this.endpoint = `/scolarite_payments/cpayment.json`;

    if (this.credentialService.isAgence()) {
      this.endpoint = `/agences/${this.credentialService.credentials.user.agence_user.agence.id}${this.endpoint}`;
    }

    return this.http.post<Response>(this.endpoint, data).pipe(
      map((response) => {
        return response.code === 0;
      })
    );
  }
}
