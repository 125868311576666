import { Injectable } from '@angular/core';
import { AcademicYearsService } from '@app/admin/souclou-digital/academic-years/academic-years.service';
import { AuthorizationService } from '@authorization/authorization.service';
import { CorporateModuleService } from '@core/corporate-module.service';
import { CredentialService } from '@core/credential.service';
import { environment } from '@env/environment';
import { AgenceAcademicYear } from '@interface/agence-academic-year';
import { AgenceAcademicYearState } from '@lidar/stores/agence-academic-year.state';
import { Store } from '@lidar/stores/store';
import { tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class AgenceAcademicYearStore extends Store<AgenceAcademicYearState> {

  env = environment;

  constructor() {
    super(new AgenceAcademicYearState());
  }

  with(agenceAcademicYear: AgenceAcademicYear, agenceSecteurs?: any) {
    console.log()
    const a = new AgenceAcademicYearState();
    a.active = agenceAcademicYear;
    a.agenceSecteurs = agenceSecteurs;
    this.state = a;
  }
}
