import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { CredentialService } from '@core/credential.service';
import { environment } from '@env/environment';
import * as moment from 'moment';
import { MessageService } from 'primeng/api';

@Injectable({
  providedIn: 'root'
})
export class AuthenticationGuard implements CanActivate {

  second = 1440;

  constructor(
    private router: Router,
    private messageService: MessageService,
    private credentialsService: CredentialService,
  ) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    
    if (this.credentialsService.isAuthenticated()) {
      if (!this.isExpired()) {
        const cred = this.credentialsService.credentials;
        cred.timestamp = moment().toISOString();
        this.credentialsService.setCredentials(cred);

        return true;
      } else {
        this.messageService.add({
          severity: 'info',
          summary: `Votre session a expirée`,
          detail: `Vous avez passé plus de ${environment.inactivityTime} secondes d'inactivité. Veuillez vous reconnecter à nouveau.`,
          closable: true,
          sticky: true
        });
      }
    }
    this.router.navigate(['/auth/login'], { queryParams: { redirect: state.url }, replaceUrl: true }).then();

    return false;
  }

  private isExpired() {
    const future = moment(this.credentialsService.credentials.timestamp).add(environment.inactivityTime, 'seconds');
    return moment().isAfter(future);
  }

}
