<div class="content-i">

  <div class="content-box">

    <ng-template #chooseFilter>
      <div class="element-wrapper">
        <h6 class="element-header">Inscriptions</h6>
        <div class="element-content">
          <div class="alert alert-warning text-center" role="alert">
            <strong class="fa-2x">Information ! </strong><br/>
            Vous devez choisir un Établissement et une Année Académique dans le filtre de recherche.
          </div>
        </div>
      </div>
    </ng-template>

    <ng-container *ngIf="registrations$ | async as registrations else chooseFilter">
      <div class="element-wrapper">
        <div class="element-content">
          <div class="row">

            <div class="col-sm-4 col-xxxl-4">
              <a class="element-box el-tablo centered smaller" href="javascript:;"
                 style="background-color: #0d509a; color: #fff;">
                <div class="label" style="color: #fff; size: 16px !important; font-weight: bold">Total
                  Inscriptions
                </div>
                <div class="value">{{ registrations.length | number }}</div>
              </a>
            </div>

          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-sm-12">
          <div class="element-box-tp">
            <div class="table-responsive">
              <table class="table table-padded">
                <thead>
                <tr>
                  <th></th>
                  <th>Nom</th>
                  <th>Prénoms</th>
                  <th>Classe</th>
                  <th class="text-right">Date inscription</th>
                </tr>
                </thead>
                <tbody>
                <tr *ngIf="registrations.length === 0">
                  <td colspan="5" class="text-center">Aucun enrégistrement ne correspond à ce filtre.</td>
                </tr>

                <ng-container *ngFor="let registration of registrations">
                  <tr>
                    <td class="text-center"><input class="form-control" type="checkbox"></td>
                    <td><span>{{ registration.student.nom | uppercase }}</span></td>
                    <td><span>{{ registration.student.prenoms | uppercase }}</span></td>
                    <td>
                      <div class="smaller">{{ registration.classroom.name | uppercase }}</div>
                    </td>
                    <td
                      class="text-right">{{ registration.created | date }}
                    </td>
                  </tr>
                </ng-container>

                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </ng-container>

  </div>

  <div class="content-panel">
    <div *ngIf="agences$ | async" class="element-wrapper">
      <div class="element-box">
        <form [formGroup]="form" (ngSubmit)="submit()">

          <div class="element-box-header">
            <h5 class="form-header">Filtre de recherche</h5>
          </div>

          <div class="form-desc"></div>

          <div class="element-box-content">
            <div class="form-group">
              <label for="agence_id" class="label">Établissement</label>
              <p-dropdown
                [showClear]="true"
                [filter]="true"
                [autoDisplayFirst]="true"
                [resetFilterOnHide]="true"
                [options]="agenceItems"
                styleClass="col-12"
                formControlName="agence_id"
                placeholder="(Choisir parmi la liste)"
                inputId="agence_id"
                id="agence_id"
              ></p-dropdown>
            </div>

            <div class="form-group">
              <label for="agence_academic_year_id" class="label">Année Académique</label>
              <p-dropdown
                [showClear]="true"
                [filter]="true"
                [autoDisplayFirst]="true"
                [resetFilterOnHide]="true"
                [options]="agenceAcademicYearItems"
                styleClass="col-12"
                formControlName="agence_academic_year_id"
                placeholder="(Choisir parmi la liste)"
                inputId="agence_academic_year_id"
                id="agence_academic_year_id"
              ></p-dropdown>
            </div>

            <div class="form-group">
              <label for="filiere_id" class="label">Filière</label>
              <p-dropdown
                [showClear]="true"
                [filter]="true"
                [autoDisplayFirst]="true"
                [resetFilterOnHide]="true"
                [options]="filiereItems"
                styleClass="col-12"
                formControlName="filiere_id"
                placeholder="(Choisir parmi la liste)"
                inputId="filiere_id"
                id="filiere_id"
              ></p-dropdown>
            </div>
          </div>
        </form>

      </div>
    </div>
  </div>

</div>
