import {Component, OnDestroy, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {SelectItem} from "primeng/api";
import {Observable} from "rxjs";
import {Agence} from "@interface/agence";
import {AgenceService} from "@app/admin/agences/shared/services/agence.service";
import {HttpParams} from "@angular/common/http";
import {untilDestroyed} from "ngx-take-until-destroy";
import {tap} from "rxjs/operators";
import {Registration} from "@interface/registration";
import {ScolaritePayment} from "@interface/scolarite-payment";
import {
  ScolaritePaiementsService
} from "@app/admin/souclou-digital/scolarites/scolarite-paiements/scolarite-paiements.service";

@Component({
  selector: 'app-entite-paiements',
  templateUrl: './entite-paiements.component.html',
  styleUrls: ['./entite-paiements.component.css']
})
export class EntitePaiementsComponent implements OnInit, OnDestroy {

  form: FormGroup;
  isLoading: boolean = false;
  agenceItems: Array<SelectItem>;
  agenceAcademicYearItems: Array<SelectItem>;
  filiereItems: Array<SelectItem>;

  scolaritePayments$: Observable<Array<ScolaritePayment>>;
  scolaritePayments: Array<ScolaritePayment> = [];
  agences$: Observable<Array<Agence>>;
  agenceDatas: Array<Agence> = [];

  constructor(
    private fb: FormBuilder,
    private agenceService: AgenceService,
    private scolaritePaiementsService: ScolaritePaiementsService,
  ) { }

  get f() { return this.form; }

  get agenceId() { return this.f.get('agence_id'); }

  get agenceAcademicYearId() { return this.f.get('agence_academic_year_id'); }

  get filiereId() { return this.f.get('filiere_id'); }

  get agences(): Observable<Agence[]> {
    return this.agenceService
      .index()
      .pipe(
        untilDestroyed(this),
        tap((agences: Array<Agence>) => {
          this.agenceDatas = agences;
          this.agenceItems = [];

          agences.forEach(agence => {
            this.agenceItems.push({
              label: agence.name.toUpperCase(),
              value: agence.id
            });
          });
        })
      );
  }

  get payments(): Observable<Array<ScolaritePayment>> {
    const params: HttpParams = new HttpParams()
      .set('agence_academic_year_id', String(this.agenceAcademicYearId.value))
      .set('agence_id', String(this.agenceId.value));
    return this.scolaritePaiementsService
      .getList(params)
      .pipe(
        untilDestroyed(this),
        tap(() => {
          this.isLoading = false;
        }),
      );
  }

  ngOnDestroy() {
  }

  ngOnInit() {
    this.form = this.fb.group({
      agence_id: ['', Validators.required],
      agence_academic_year_id: ['', Validators.required],
    });

    this.agences$ = this.agences;

    this.agenceAcademicYearId.valueChanges
      .pipe(
        untilDestroyed(this),
        tap((next) => {
          if (next !== '') {
            this.scolaritePayments$ = this.payments;
          }
        })
      ).subscribe();

    this.agenceId.valueChanges
      .pipe(
        untilDestroyed(this),
        tap(next => {
          this.agenceAcademicYearId.setValue('');

          if (next !== '') {
            this.agenceAcademicYearItems = [];

            this.agenceDatas.forEach(agence => {
              if (next === agence.id) {
                agence.agence_academic_years.forEach(year => {
                  this.agenceAcademicYearItems.push({
                    label: year.academic_year.name.toUpperCase(),
                    value: year.id
                  })
                });
              }
            });
          }
        })
      ).subscribe();
  }

  submit() {
    this.isLoading = true;
    this.scolaritePayments$ = this.payments;
  }

  getAmount(payments: Array<ScolaritePayment>): number {
    let amount = 0;
    payments.forEach(payment => {
      amount += payment.montant;
    })
    return amount;
  }

}
