import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AbstractService } from '@core/api';
import { CredentialService } from '@core/credential.service';
import { AgenceAcademicYear } from '@interface/agence-academic-year';
import { Response } from '@model/response';
import { Observable } from 'rxjs';
import { map, shareReplay } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class AcademicYearsService extends AbstractService {

  constructor(
    private credentialService: CredentialService,
    private http: HttpClient
  ) {
    super();
  }

  getList(params?: HttpParams): Observable<AgenceAcademicYear[]> {
    this.endpoint = "/agence_academic_years.json";

    if (this.credentialService.isAgence()) {
      this.endpoint = `/agences/${this.credentialService.credentials.user.agence_user.agence.id}${this.endpoint}`;
    }

    return this.http
      .get<Response>(this.endpoint, { params })
      .pipe(
        shareReplay(),
        map((response) => {
          if (response.code === 0) {
            return response.agence_academic_years;
          }
          throw new Error(response.errorMessage);
        })
      );
  }

  getAll(data: any): Observable<any> {
    this.endpoint = "/agence_academic_years/all.json";

    if (this.credentialService.isAgence()) {
      this.endpoint = `/agences/${this.credentialService.credentials.user.agence_user.agence.id}${this.endpoint}`;
    }

    return this.http
      .post<any>(this.endpoint, data)
      .pipe(
        shareReplay()
      );
  }

  create(data: any): Observable<AgenceAcademicYear> {
    this.endpoint = `/agence_academic_years.json`;

    if (this.credentialService.isAgence()) {
      this.endpoint = `/agences/${this.credentialService.credentials.user.agence_user.agence.id}${this.endpoint}`;
    }

    return this.http
      .post<Response>(this.endpoint, data)
      .pipe(
        map((response) => {
          if (response.code === 0) {
            return response.agence_academic_year;
          }
          throw new Error(response.errorMessage);
        })
      );
  }

  getActive(): Observable<any> {
    this.endpoint = "/agence_academic_years.json";

    if (this.credentialService.isAgence()) {
      this.endpoint = `/agences/${this.credentialService.credentials.user.agence_user.agence.id}${this.endpoint}`;
    }

    const params: HttpParams = new HttpParams()
      .set('statut', '1');

    return this.http
      .get<Response>(this.endpoint, { params })
      .pipe(
        map((response) => {
          if (response.code === 0 && response.agence_academic_years.length > 0) {
            return response;
          }
          throw new Error("No academic year found");
        }),

      );
  }

  read(id: any): Observable<any> {
    this.endpoint = `/agence_academic_years/${id}.json`;

    if (this.credentialService.isAgence()) {
      this.endpoint = `/agences/${this.credentialService.credentials.user.agence_user.agence.id}${this.endpoint}`;
      console.log('endpoint ',this.endpoint);
    }

    return this.http
      .get<Response>(this.endpoint)
      .pipe(
        map((response) => response),
      );
  }

}
