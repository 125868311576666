import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AdminComponent } from '@app/admin/admin.component';
import { DashboardComponent } from '@app/admin/dashboard/dashboard.component';
import { Error404Component } from '@app/admin/error404/error404.component';
import { AuthenticationGuard } from '@core/authentication.guard';
import {EntitePaiementsComponent} from "@app/admin/dashboard/entite-paiements/entite-paiements.component";
import {EntiteInscriptionsComponent} from "@app/admin/dashboard/entite-inscriptions/entite-inscriptions.component";

const routes: Routes = [
  {
    path: 'admin',
    component: AdminComponent,
    children: [
      {
        path: 'dashboard',
        canActivate: [AuthenticationGuard],
        children: [
          {path: 'inscriptions', component: EntiteInscriptionsComponent},
          {path: 'paiements', component: EntitePaiementsComponent},
          {path: '', component: DashboardComponent},
        ]
      },
      { path: 'profiles', loadChildren: () => import('./profiles/profiles.module').then(mod => mod.ProfilesModule) },

      // Deprecated. User `utilisateurs` path instead
      { path: 'users', loadChildren: () => import('./users/users.module').then(mod => mod.UsersModule) },
      { path: 'utilisateurs', loadChildren: () => import('./utilisateurs/utilisateurs.module').then(mod => mod.UtilisateursModule) },

      { path: 'entreprises', loadChildren: () => import('./entreprises/entreprises.module').then(mod => mod.EntreprisesModule) },
      { path: 'agences', loadChildren: () => import('./agences/agences.module').then(mod => mod.AgencesModule) },

      { path: 'operations', loadChildren: () => import('./operations/operations.module').then(mod => mod.OperationsModule) },

      { path: 'settings', loadChildren: () => import('./settings/settings.module').then(mod => mod.SettingsModule) },
      { path: 'reporting', loadChildren: () => import('./reporting/reporting.module').then(m => m.ReportingModule) },
      { path: 'annonces', loadChildren: () => import('./annonces/annonces.module').then(m => m.AnnoncesModule) },
      { path: 'myaccount', loadChildren: () => import('./myaccount/myaccount.module').then(m => m.MyaccountModule) },
      { path: '', loadChildren: () => import('./souclou-digital/souclou-digital.module').then(m => m.SouclouDigitalModule) },
      { path: '', redirectTo: 'dashboard', pathMatch: 'full' },
      { path: '**', component: Error404Component },
    ],
    canActivate: [AuthenticationGuard],
    data: { reuse: true }
  }
];

@NgModule({
  imports: [
    RouterModule.forChild(routes)
  ],
  exports: [RouterModule]
})
export class AdminRoutingModule {
}
