import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MenuItem } from 'primeng/api';

export enum ElementHeaderActions {
  back = 'back',
  edit = 'edit',
  create = 'create',
  refresh = 'refresh',
  print = 'print',
}

@Component({
  selector: 'app-content-tpl',
  templateUrl: './content-template.component.html',
  styleUrls: ['./content-template.component.css']
})
export class ContentTemplateComponent implements OnInit {

  @Input()
  items: MenuItem[];

  @Input()
  title: string;

  // tslint:disable-next-line:no-input-rename
  @Input('element-box-title')
  elementBoxTitle: string;

  // tslint:disable-next-line:no-input-rename
  @Input('element-box-desc')
  elementBoxDesc: string;

  // tslint:disable-next-line:no-input-rename
  @Input('element-box-tp')
  elementBoxTp = true;

  /**
   * Back btn feature
   */
  // tslint:disable-next-line:no-input-rename
  @Input('back-label')
  backLabel = 'Retour';

  // tslint:disable-next-line:no-input-rename
  @Input('refresh-icon')
  backIcon = 'os-icon os-icon-arrow-left5';

  // tslint:disable-next-line:no-output-rename
  @Output('click-on-back')
  clickOnBack: EventEmitter<any> = new EventEmitter<any>();

  /**
   * Print btn feature
   */
  // tslint:disable-next-line:no-input-rename
  @Input('print-label')
  printLabel = 'Imprimer';

  // tslint:disable-next-line:no-input-rename
  @Input('refresh-icon')
  printIcon = 'os-icon os-icon-download';

  // tslint:disable-next-line:no-output-rename
  @Output('click-on-print')
  clickOnPrint: EventEmitter<any> = new EventEmitter<any>();

  /**
   * Refresh btn feature
   */
  // tslint:disable-next-line:no-input-rename
  @Input('refresh-label')
  refreshLabel: string;

  // tslint:disable-next-line:no-input-rename
  @Input('refresh-icon')
  refreshIcon = 'os-icon os-icon-refresh-cw';

  // tslint:disable-next-line:no-output-rename
  @Output('click-on-refresh')
  clickOnRefresh: EventEmitter<any> = new EventEmitter<any>();

  /**
   * Edit btn feature
   */
  // tslint:disable-next-line:no-input-rename
  @Input('edit-label')
  editLabel = 'Editer';

  // tslint:disable-next-line:no-input-rename
  @Input('edit-icon')
  editIcon = 'os-icon os-icon-edit';

  // tslint:disable-next-line:no-input-rename
  @Input('edit-required-master')
  editRequiredMaster = false;

  // tslint:disable-next-line:no-input-rename
  @Input('edit-required-admin')
  editRequiredAdmin = false;

  // tslint:disable-next-line:no-input-rename
  @Input('edit-required-profiles')
  editRequiredProfiles: string[] = [];

  // tslint:disable-next-line:no-input-rename
  @Input('edit-required-actions')
  editRequiredActions: string[] = [];

  // tslint:disable-next-line:no-output-rename
  @Output('click-on-edit')
  clickOnEdit: EventEmitter<any> = new EventEmitter<any>();

  /**
   * Create btn feature
   */
  // tslint:disable-next-line:no-input-rename
  @Input('create-label')
  createLabel = 'Ajouter';

  // tslint:disable-next-line:no-input-rename
  @Input('create-icon')
  createIcon = 'os-icon os-icon-plus';

  // tslint:disable-next-line:no-input-rename
  @Input('create-required-master')
  createRequiredMaster = false;

  // tslint:disable-next-line:no-input-rename
  @Input('create-required-admin')
  createRequiredAdmin = false;

  // tslint:disable-next-line:no-input-rename
  @Input('create-required-profiles')
  createRequiredProfiles: string[] = [];

  // tslint:disable-next-line:no-input-rename
  @Input('create-required-actions')
  createRequiredActions: string[] = [];

  // tslint:disable-next-line:no-input-rename
  @Input('enable-element-header-actions')
  enableElementHeaderActions: ElementHeaderActions[] = [];

  // tslint:disable-next-line:no-output-rename
  @Output('click-on-create')
  clickOnCreate: EventEmitter<any> = new EventEmitter<any>();

  elementHeaderAction = ElementHeaderActions;

  constructor() {
  }

  ngOnInit() {
    this.items = [
      { label: 'Update', icon: 'pi pi-refresh', command: () => { } },
      { label: 'Delete', icon: 'pi pi-times', command: () => { } },
      { label: 'Angular.io', icon: 'pi pi-info', url: 'http://angular.io' },
      { separator: true },
      { label: 'Setup', icon: 'pi pi-cog', routerLink: ['/setup'] }
    ];

  }

  refresh(event: any) {
    this.clickOnRefresh.emit(event);
  }

  print(event: any) {
    this.clickOnPrint.emit(event);
  }

  create(event: any) {
    this.clickOnCreate.emit(event);
  }

  edit(event: any) {
    this.clickOnEdit.emit(event);
  }

  back(event: any) {
    this.clickOnBack.emit(event);
  }

  requestElementHeader(action: string): boolean {
    return this.enableElementHeaderActions.find((elm) => elm === action) === action;
  }
}
