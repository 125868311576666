import { CommonModule } from '@angular/common';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ForgotComponent } from '@auth/forgot/forgot.component';
import { RecaptchaModule } from 'ng-recaptcha';
import { ButtonModule } from 'primeng/button';
import { CheckboxModule } from 'primeng/checkbox';
import { InputTextModule } from 'primeng/inputtext';
import { PasswordModule } from 'primeng/password';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { ToastModule } from 'primeng/toast';

import { AuthRoutingModule } from './auth-routing.module';
import { AuthComponent } from './auth.component';
import { InviteComponent } from './invite/invite.component';
import { LoginComponent } from './login/login.component';
import { NoaccessComponent } from './noaccess/noaccess.component';
import { SigninComponent } from './signin/signin.component';
import { Signinv2Component } from './signinv2/signinv2.component';
import { PreInscrptionComponent } from './pre-inscrption/pre-inscrption.component';
import { Ng2TelInputModule } from 'ng2-tel-input';
import {
  AccordionModule,
  AutoCompleteModule,
  ContextMenuModule,
  DropdownModule,
  FieldsetModule,
  InputNumberModule,
  InputTextareaModule,
  ListboxModule,
  PanelModule,
  ScrollPanelModule,
  SplitButtonModule,
  TooltipModule,
} from 'primeng';
import { InputSwitchModule } from 'primeng/inputswitch';
import { RadioButtonModule } from 'primeng/radiobutton';
import { SelectButtonModule } from 'primeng/selectbutton';
@NgModule({
  declarations: [LoginComponent, AuthComponent, ForgotComponent, InviteComponent, SigninComponent, NoaccessComponent, Signinv2Component, PreInscrptionComponent],
  bootstrap: [AuthComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  imports: [
    SelectButtonModule,
    RadioButtonModule,
    InputSwitchModule,
    AccordionModule,
    AutoCompleteModule,
    ContextMenuModule,
    DropdownModule,
    FieldsetModule,
    InputNumberModule,
    InputTextareaModule,
    ListboxModule,
    PanelModule,
    ScrollPanelModule,
    SplitButtonModule,
    TooltipModule,
    CommonModule,
    FormsModule,
    ToastModule,
    CheckboxModule,
    AuthRoutingModule,
    InputTextModule,
    Ng2TelInputModule,
    PasswordModule,
    RecaptchaModule,
    ReactiveFormsModule,
    ButtonModule,
    ProgressSpinnerModule,
    
  ]
})
export class AuthModule {
}
