import { HttpClient } from '@angular/common/http';
import { AfterViewInit, Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AcademicYearsService } from '@app/admin/souclou-digital/academic-years/academic-years.service';
import { CorporateModuleService } from '@core/corporate-module.service';
import { Credentials, CredentialService } from '@core/credential.service';
import { Profiles } from '@core/profiles.enum';
import { WfRoles } from '@core/roles.enum';
import { ShellService } from '@core/shell.service';
import { untilDestroyed } from '@core/until-destroyed';
import { environment } from '@env/environment';
import { AgenceAcademicYear } from '@interface/agence-academic-year';
import { AgenceAcademicYearStore } from '@lidar/stores/agence-academic-year.store';
import { Agence } from '@model/agence';
import { Operation } from '@model/operation';
import 'bootstrap';
import 'datatables.net';
import 'datatables.net-bs4';
import * as $ from 'jquery';
import { Observable, of } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';
import 'select2';
import { AuthorizationService } from 'src/@autorization/authorization.service';
import { AnneeAcademiqueActive } from '@lidar/stores/annee-academique-active';


export const BUDGET_PREVISIONNEL_DESIGNATIONS = [
  'Payer les salaires',
  'Recharger la caisse de Dépense',
];

@Component({
  selector: 'app-admin',
  templateUrl: './admin.component.html',
  styleUrls: ['./admin.component.css'],
})
export class AdminComponent implements OnInit, AfterViewInit, OnDestroy {

  Profiles = Profiles;
  WfRole = WfRoles;

  env = environment;
  credential: Credentials;
  wrapper: any;
  body: any;
  menu: any;
  topBar: any;
  agence: Agence;
  solde$: Observable<any>;
  annonces$: Observable<any>;
  academicYear: AgenceAcademicYear;
  orabankOperations: Operation[];

  @ViewChild('fancySelectorW', { static: true }) fancySelectorWElm: ElementRef;

  constructor(
    private anneeAcademique: AnneeAcademiqueActive,
    private router: Router,
    public route: ActivatedRoute,
    private http: HttpClient,
    private shellService: ShellService,
    public corporate: CorporateModuleService,
    public credentialService: CredentialService,
    public auth: AuthorizationService,
    private academicYearService: AcademicYearsService,
    public agenceAcademicYearStore: AgenceAcademicYearStore,
  ) {
  }

  static sharedFunc() {
    // #11. MENU RELATED    $('[data-toggle="popover"]').popover(); STUFF
    // INIT MOBILE MENU TRIGGER BUTTON
    $('.mobile-menu-trigger').on('click', () => {
      $('.menu-mobile .menu-and-user').slideToggle(200, 'swing');
      return false;
    });

    // #12. CONTENT SIDE PANEL TOGGLER
    $('.content-panel-toggler, .content-panel-close, .content-panel-open').on('click', () => {
      const allW = document.querySelector('.all-wrapper');
      if ($(allW).hasClass('content-panel-active')) {
        $(allW).removeClass('content-panel-active');
      } else {
        $(allW).addClass('content-panel-active');
      }
    });

    $('.content-box').on('click', () => {
      const allW = document.querySelector('.all-wrapper');
      if ($(allW).hasClass('content-panel-active')) {
        $(allW).removeClass('content-panel-active');
      }
    });

    // #16. OUR OWN CUSTOM DROPDOWNS
    const osDropDownTrigger = $(document.querySelector('.os-dropdown-trigger'));
    osDropDownTrigger.first().on('mouseenter', (e) => {
      $(e.currentTarget).addClass('over');
    });
    osDropDownTrigger.on('mouseleave', (e) => {
      $(e.currentTarget).removeClass('over');
    });

    // #17. BOOTSTRAP RELATED JS ACTIVATIONS
    // - Activate tooltips
    $('[data-toggle="tooltip"]').tooltip();
    // - Activate popovers

    // #19. Fancy Selector
    $(document.querySelector('.fancy-selector-actions > a')).on('click', (e) => {
      $(e.currentTarget).closest('.fancy-selector-w').removeClass('opened');
    });

    $('.fancy-selector-option').on('click', (e) => {
      $(e.currentTarget).closest('.fancy-selector-w').removeClass('opened');
    });

    // #24. Element Actions
    $(document.querySelector('.element-action-fold')).on('click', (e) => {
      const $wrapper = $(e.currentTarget).closest('.element-wrapper');
      $wrapper.find('.element-box-tp, .element-box').toggle(0);
      const $icon = $(e.currentTarget).find('i');

      if ($wrapper.hasClass('folded')) {
        $icon.removeClass('os-icon-plus-circle').addClass('os-icon-minus-circle');
        $wrapper.removeClass('folded');
      } else {
        $icon.removeClass('os-icon-minus-circle').addClass('os-icon-plus-circle');
        $wrapper.addClass('folded');
      }
      return false;
    });
  }

  static osInitSubMenus() {

    // INIT MENU TO ACTIVATE ON HOVER
    let menuTimer;
    const menuActivatedOnHover = $('.menu-activated-on-hover');
    menuActivatedOnHover.on('mouseenter', 'ul.main-menu > li.has-sub-menu', (e) => {
      const $elem = $(e.currentTarget);
      clearTimeout(menuTimer);
      $elem.closest('ul').addClass('has-active').find('> li').removeClass('active');
      $elem.addClass('active');
    });

    menuActivatedOnHover.on('mouseleave', 'ul.main-menu > li.has-sub-menu', (e) => {
      const $elem = $(e.currentTarget);
      menuTimer = setTimeout(() => {
        $elem.removeClass('active').closest('ul').removeClass('has-active');
      }, 30);
    });

    const menuActivatedOnClick = $('.menu-activated-on-click');
    // INIT MENU TO ACTIVATE ON CLICK
    menuActivatedOnClick.on('click', 'li.has-sub-menu > a', (event) => {
      const $elem = $(event.currentTarget).closest('li');
      if ($elem.not('active')) {
        $elem.closest('ul').find('li.active').removeClass('active');
        $elem.addClass('active');
      }
      return false;
    });
  }

  ngOnInit() {
    this.anneeAcademique.state$
      .pipe(
        untilDestroyed(this),
        map(data => data),
        tap(next => {
          if (next === null) {
            this.router.navigate(['/admin/settings/annee-academique']);
          }
        })
      ).subscribe();

    this.orabankOperations = [];

    // Subscribe to store
    this.agenceAcademicYearStore
      .state$
      .pipe(
        tap(next => {
          if (next && next.active) {
            this.academicYear = next.active;
          }
        })
      )
      .subscribe();

    this.corporate.getData();
    this.corporate.getState().subscribe((data) => {
      if (this.credentialService.isAgence()) {
        if (data !== null && this.corporate.canAgence(data, [this.env.corporate.SD])) {
          this.agence = data;
         //console.log('data agence',this.agence);
          if (this.credentialService.credentials.user.user_agence_academic_year !== null) {
            this.academicYearService.read(this.credentialService.credentials.user.user_agence_academic_year.agence_academic_year.id)
              .pipe(
                tap(response => {
                  this.agenceAcademicYearStore.with(response.agence_academic_year, response.agence_secteurs)
                })
              )
              .subscribe();
          }

          // this.academicYearService
          //   .getActive()
          //   .pipe(
          //     tap(response => {
          //       this.agenceAcademicYearStore.with(response.agence_academic_years[0], response.agence_secteurs)
          //     })
          //   )
          //   .subscribe();
        }
      }
    });

    /*
  */

    if (this.credentialService.isMulti()) {
      if (this.auth.canAccess([this.WfRole.canOraOp])) {
        if (!this.auth.isAdmin() && !this.auth.isMaster()) {
          // this.checker();
          // this.clearInterval();
        }
      }
    }

    this.solde$ = this.reloadSolde(null);
    this.annonces$ = this.getAnnonces();

    $('input[required]').parent().parent().find('label').addClass('required');
    $('textarea[required]').parent().parent().find('label').addClass('required');
    $('select[required]').parent().parent().find('label').addClass('required');

    this.credential = this.credentialService.credentials;
    this.body = document.querySelector('body');
    this.menu = document.querySelector('div.menu-w');
    this.wrapper = document.querySelector('div.all-wrapper');
    this.topBar = document.querySelector('div.top-bar');

    $(this.menu)
      .addClass('selected-menu-color-light')
      .addClass('menu-has-selected-link')
      .addClass('menu-activated-on-click')
      .addClass('color-scheme-dark')
      .addClass('sub-menu-color-bright')
      .addClass('menu-position-side')
      .addClass('menu-side-left')
      .addClass('menu-layout-compact')
      .addClass('sub-menu-style-inside');
  }

  ngAfterViewInit(): void {
    $(this.body)
      .addClass('menu-position-side menu-side-left full-screen with-content-panel');

    $(this.wrapper)
      .addClass('with-side-panel solid-bg-all');

    $(this.menu)
      .addClass(!this.credentialService.isAgence() ? 'color-style-default' : 'color-style-bright');

    if (this.credentialService.isMulti()) {
      $(this.topBar).addClass('color-scheme-light');
    }

    if (this.credentialService.isEntite()) {
      $(this.topBar).addClass('color-scheme-bright');
    }

    if (this.credentialService.isAgence()) {
      $(this.topBar).addClass('color-scheme-bright');
    }

    AdminComponent.sharedFunc();
    AdminComponent.osInitSubMenus();

    $('body').removeAttr('style');
    $('body').removeProp('style');
  }

  ngOnDestroy(): void {
    $(this.wrapper).removeClass('with-side-panel solid-bg-all');
    $(this.menu).removeClass('color-style-bright').removeClass('color-style-default');
    $(this.topBar).removeClass('color-scheme-dark').removeClass('color-scheme-bright').removeClass('color-scheme-light');
    $(this.body).removeClass('menu-position-side menu-side-left full-screen with-content-panel');
  }

  onFsSelectorTrigger($event: any) {
    $($event.currentTarget)
      .closest('.fancy-selector-w')
      .toggleClass('opened');
  }

  checker() {
    this.http
      .post(`/operations/getAll.json`, { obc: true, obcState: 1, user_id: this.credentialService.credentials.user.id })
      .pipe(untilDestroyed(this))
      .subscribe(
        res => {
          const json = JSON.parse(JSON.stringify(res));

          if (json.code === 0) {
            this.orabankOperations = json.operations;
          }
        },
        error => {
        }
      );
  }

  clearInterval() {
    setInterval(() => {
      this.checker();
    }, 15000);
  }

  goOrabankOp() {
    this.router.navigate(['/admin/operations/index/orabank-op']).then(() => {
      window.location.reload();
    });
  }

  getReloadSolde($event: any) {
    this.solde$ = this.reloadSolde(null);
  }

  private getAnnonces(): Observable<any[]> {
    const endpoint = '/annonces.json';
    console.log('get annonce')
    return this.http
      .get(`/annonces.json`)
      .pipe(
        untilDestroyed(this),
        map(res => {
          const json = JSON.parse(JSON.stringify(res));
          return json.annonces;
        }),
      );
  }


  reloadSolde($event: any): Observable<any> {
    let carteId = null;

    if (this.credentialService.isMulti()) {
      carteId = 1;
    }
    if (this.credentialService.isEntite()) {
      carteId = this.credentialService.credentials.user.entite_user.entite.entite_carte.carte_id;
    }
    if (this.credentialService.isAgence()) {
      carteId = this.credentialService.credentials.user.agence_user.agence.agence_carte.carte_id;
    }

    if (carteId !== null) {
      return this.shellService
        .getRemoteSolde(carteId)
        .pipe(
          map(resp => resp),
          catchError(err => {
            return of(err);
          }),
        );
    }
  }
}
